"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HammerService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var HammerService = /** @class */ (function () {
    function HammerService() {
    }
    /**
     * List Hammers
     * @returns Hammer The list of hammers.
     * @throws ApiError
     */
    HammerService.hammerListHammers = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/hammer/',
        });
    };
    /**
     * Persist Hammers
     * @param requestBody
     * @returns Hammer The list of persisted hammers.
     * @throws ApiError
     */
    HammerService.hammerPersistHammers = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/hammer/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Commit Hammers
     * @returns any The list of commited hammers.
     * @throws ApiError
     */
    HammerService.hammerCommitHammers = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/hammer/commit',
        });
    };
    return HammerService;
}());
exports.HammerService = HammerService;
