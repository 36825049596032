import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import DiscardDialog from "./DiscardDialog";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../AppRouter";

const Wizard = ({
    steps,
    currentStep,
    stepValidation,
    hasChanges,
    forceEnableSave,
    disableStepper,
    basePath,
    originPath,
    onSave,
    onDiscard,
    children,
}) => {
    const { config } = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [showExitDialog, setShowExitDialog] = useState(false);

    const indexStep = steps.findIndex(step => step === currentStep);

    const goBack = () => {
        if (indexStep === 0) {
            navigate(originPath);
        } else if (indexStep > 0) {
            const previousStep = steps[indexStep - 1];
            navigate(`${generatePath(basePath, params)}/${previousStep}`);
        }
    };

    return (
        <>
            <DiscardDialog
                open={showExitDialog}
                onDiscard={() => {
                    onDiscard();
                    setShowExitDialog(false);
                    goBack();
                }}
                onCancel={() => setShowExitDialog(false)}
            />
            <Box sx={{ height: "100%" }}>
                {
                    !disableStepper
                        ? (
                            <Stepper activeStep={indexStep} sx={{ my: 2 }}>
                                {
                                steps.map((step, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    const typoProps = {};

                                    if (index < indexStep) {
                                        stepProps.completed = true;
                                    }

                                    labelProps.error = stepValidation[step] && !(stepValidation[step].valid);

                                    if (index === steps.length - 1) {
                                        stepProps.last = true;
                                    }

                                    // show current step with some highlight.
                                    if (index === indexStep) {
                                        // add a border to the current step.
                                        // The theme's remaining properties are global. Check the App.js.
                                        labelProps.sx = {
                                            borderRadius: 1,
                                            p: 1,
                                            pr: 2,
                                            pl: 2.5,
                                        };
                                    }

                                    // TODO: Show reasons.
                                    return (
                                        <Step key={step} {...stepProps}>
                                            <StepLabel {...labelProps}>
                                                <Typography {...typoProps}>
                                                    {config.i18n.procurement[step].title}
                                                </Typography>
                                            </StepLabel>
                                        </Step>
                                    );
                                })
                            }
                            </Stepper>
                            )
                        : null
                }

                {children}

                <Box sx={{ mt: 1 }}>
                    <Button
                        variant="contained"
                        color="grey"
                        sx={{ mr: 1 }}
                        title={config.i18n.button.back}
                        startIcon={<NavigateBeforeIcon />}
                        onClick={() => {
                            if (hasChanges) {
                                setShowExitDialog(true);
                            } else {
                                goBack();
                            }
                        }}
                    >
                        {config.i18n.button.back}
                    </Button>
                    <Button
                        data-cy="save_button"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        // make save button active if we never saved the schedule.
                        disabled={!(hasChanges || forceEnableSave)}
                        onClick={() => {
                            onSave();
                        }}
                        sx={{ mr: 1 }}
                        title={config.i18n.button.save}
                    >
                        {config.i18n.button.save}
                    </Button>
                    {
                        indexStep !== steps.length - 1
                            ? (
                                <Button
                                    data-cy="next_button"
                                    variant="contained"
                                    sx={{ mr: 1 }}
                                    title={config.i18n.button.next}
                                    endIcon={<NavigateNextIcon />}
                                    disabled={hasChanges || !(indexStep >= 0 && stepValidation[steps[indexStep]]?.valid)}
                                    onClick={() => {
                                        const nextStep = steps[indexStep + 1];
                                        navigate(`${generatePath(basePath, params)}/${nextStep}`);
                                    }}
                                >
                                    {config.i18n.button.next}
                                </Button>
                                )
                            : null
                    }
                </Box>
            </Box>
        </>
    );
};

Wizard.propTypes = {
    steps: PropTypes.array,
    currentStep: PropTypes.string,
    stepValidation: PropTypes.object,
    hasChanges: PropTypes.bool,
    forceEnableSave: PropTypes.bool,
    disableStepper: PropTypes.bool,
    basePath: PropTypes.string,
    originPath: PropTypes.string,
    onSave: PropTypes.func,
    onDiscard: PropTypes.func,
    children: PropTypes.any,
};

export default Wizard;
