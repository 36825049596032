import { OpenAPI } from "../client";
import { isEmpty } from "lodash";
import { mergeFilters } from "./object_util";
import { allEqual } from "./string";

export function downloadReport(name, reportId, requestBody, onDownloadComplete, onDownloadError) {
    // the OpenAPI generated client does not handle binary responses
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "accept": "application/xlsx",
            "authorization": "Bearer " + OpenAPI.TOKEN,
        },
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(requestBody),
    };
    fetch(`/api/report/${reportId}/download`, options)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                return Promise.reject(response);
            }
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;

            // avoid report naming to be all dots, to avoid path confusion when the browser is downloading the file.
            a.download = allEqual(name, ".") ? "_.xlsx" : `${name}.xlsx`;

            document.body.appendChild(a);
            a.click();
            a.remove();
            onDownloadComplete();
        })
        .catch(() => {
            onDownloadError();
        });
}

export function generateReportLink(
    report_row,
    startDate,
    endDate,
    uiFiltersIn,
    uiFiltersOut,
    scenario,
    cutoffDate,
) {
    if (!report_row.analysis_link) return null;

    // Analysis parameters to visualize data exactly the same way as in report
    const analysis_parameters = {
        DATE_AGG: "DATE_YEAR_MONTH",
        DATE: [startDate, endDate],
    };

    if (!isEmpty(scenario) && cutoffDate !== null) {
        analysis_parameters.SCENARIO = scenario;
        analysis_parameters.CUTOFF_DATE = cutoffDate;
    }

    const filtersIn = mergeFilters(uiFiltersIn, report_row.global_filters_in);
    const filtersOut = mergeFilters(uiFiltersOut, report_row.global_filters_out);

    const searchParams = new URLSearchParams({
        params: JSON.stringify(analysis_parameters),
        filtersInDims: JSON.stringify(Object.keys(filtersIn)),
        filtersInParams: JSON.stringify(filtersIn),
        filtersOutDims: JSON.stringify(Object.keys(filtersOut)),
        filtersOutParams: JSON.stringify(filtersOut),
    });

    return `${report_row.analysis_link}?${searchParams.toString()}`;
}
