"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reference = void 0;
var Reference;
(function (Reference) {
    Reference["PREVIOUS_YEAR"] = "PREVIOUS_YEAR";
    Reference["BUDGET"] = "BUDGET";
})(Reference || (exports.Reference = Reference = {}));
