"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalysisService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var AnalysisService = /** @class */ (function () {
    function AnalysisService() {
    }
    /**
     * Search
     * @param q
     * @param page
     * @param limit
     * @returns PaginatedResponse_AnalysisQuestion_ The paginated searched analyses.
     * @throws ApiError
     */
    AnalysisService.analysisSearch = function (q, page, limit) {
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/analysis',
            query: {
                'q': q,
                'page': page,
                'limit': limit,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get
     * @param analysisId
     * @returns Analysis The analysis.
     * @throws ApiError
     */
    AnalysisService.analysisGet = function (analysisId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/analysis/{analysis_id}',
            path: {
                'analysis_id': analysisId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    return AnalysisService;
}());
exports.AnalysisService = AnalysisService;
