import { Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import InfoIcon from "@mui/icons-material/Info";
import React, { useContext } from "react";
import Markdown from "markdown-to-jsx";
import CircularLoading from "../components/loading/CircularLoading";
import { AppContext } from "../AppRouter";

const AdvisorContainer = ({
    title,
    toolTipInfo = null,
    subtitle,
    actions,
    showTitle,
    loading,
    loadingLabel,
    dataCyProp,
    minWidth = "auto",
    maxWidth,
    maxHeight = "auto",
    children,
    noSpacingAfterTitle = false,
}) => {
    const { config } = useContext(AppContext);

    return (
        <>
            <Helmet>
                <title>
                    {config.i18n.page.main}
                    {title ? ` - ${title}` : ""}
                </title>
            </Helmet>
            <Container
                data-cy={dataCyProp}
                maxWidth={maxWidth || "lg"}
                sx={{
                    minWidth: minWidth,
                    maxHeight: maxHeight,
                    display: "flex",
                    flexDirection: "column",
                    py: 4,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: noSpacingAfterTitle ? 0 : 2,
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "bottom" }}>
                            { showTitle
                                ? (
                                    <Typography variant="h4" align="left" data-cy="title">
                                        {title}
                                    </Typography>
                                    )
                                : null}
                            { toolTipInfo
                                ? (
                                    <Tooltip
                                        title={toolTipInfo}
                                        arrow
                                        slotProps={{
                                            tooltip: {
                                                sx: {
                                                    backgroundColor: "#e5f6fd",
                                                    color: "#014361",
                                                    fontSize: "0.875rem",
                                                    fontWeight: "400",
                                                    lineHeight: "1.43",
                                                },
                                            },
                                            arrow: {
                                                sx: {
                                                    color: "#e5f6fd",
                                                },
                                            },
                                        }}
                                    >
                                        <IconButton sx={{ ml: 1 }}>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                    )
                                : ""}
                        </Box>
                        { subtitle && !loading
                            ? (
                                <Typography variant="subtitle1" align="left" data-cy="subtitle" sx={{ fontSize: 18, color: "grey.700" }}>
                                    <Markdown>{subtitle}</Markdown>
                                </Typography>
                                )
                            : null}
                    </Box>
                    <Box>
                        { !loading ? actions : null}
                    </Box>
                </Box>
                { loading ? <CircularLoading position="absolute" height="800px" label={loadingLabel} /> : children}
            </Container>
        </>
    );
};

AdvisorContainer.propTypes = {
    title: PropTypes.string,
    toolTipInfo: PropTypes.string,
    subtitle: PropTypes.string,
    actions: PropTypes.element,
    showTitle: PropTypes.bool,
    loading: PropTypes.bool,
    loadingLabel: PropTypes.string,
    dataCyProp: PropTypes.string,
    minWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
    children: PropTypes.any,
    noSpacingAfterTitle: PropTypes.bool,
};

AdvisorContainer.defaultProps = {
    showTitle: true,
};

export default AdvisorContainer;
