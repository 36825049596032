import { formatValue } from "../formatter";

export const getFormatterFromLabelType = (labelType) => {
    switch (labelType) {
        case "INTEGER":
            return "integer";
        case "DECIMAL":
            return null;
        case "AMOUNT":
            return "longamount";
        case "DATE":
            return "date";
        case "PERCENTAGE":
            return "longpercentage";
        default:
            return null;
    }
};

export const getFormattedValue = (value, labelType, config) => {
    const formatterType = getFormatterFromLabelType(labelType);

    if (formatterType) {
        return formatValue(value, formatterType, config.locale, config.i18n);
    } else {
        return value;
    }
};
