import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportDetails from "./ReportDetails";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import { isForecastingEnabled } from "../../util/feature_flag_util";
import { createReport as createReportApi } from "../../util/client";

const CreateReport = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const isValid = (name, template, startDate, endDate) => {
        return !!(name && template && startDate && endDate);
    };

    const createReport = (name, template, scenario, startDate, endDate, cutoffDate, multiplier, hideEmptyRows, hideInitialMonths, reference, hideComparisons,
        filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters) => {
        setShouldValidate(true);
        if (isValid(name, template, startDate, endDate)) {
            setLoading(true);
            createReportApi(client, name, template.id, startDate, endDate, scenario?.id, isForecastingEnabled(config) ? cutoffDate : null, multiplier,
                hideEmptyRows, hideInitialMonths, reference, hideComparisons, filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters)
                .then(() => {
                    setLoading(false);
                    navigate("/report");
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, "report.create");
                });
        }
    };

    return (
        <ReportDetails
            operation="create"
            title={config.i18n.report.create}
            button={config.i18n.button.save}
            icon={<SaveIcon />}
            shouldValidate={shouldValidate}
            loading={loading}
            filtersDisabled
            onClick={createReport}
        />
    );
};

export default CreateReport;
