import { Autocomplete, Box, Checkbox, FormControlLabel, TextField, Grid2 as Grid } from "@mui/material";
import ClickableDatePicker from "../../components/ClickableDatePicker";
import { isForecastingEnabled } from "../../util/feature_flag_util";
import { isEmpty, omit, union, without } from "lodash";
import DimensionSelector from "../../components/selector/DimensionSelector";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../AppRouter";

const ReportCustomizationBar = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    globalMinDate,
    globalMaxDate,
    scenario,
    setScenario,
    scenarioOptions,
    multiplier,
    setMultiplier,
    multiplierOptions,
    hideRows,
    setHideRows,
    hideInitialMonths,
    setHideInitialMonths,
    reference,
    onReferenceChange,
    referenceOptions,
    showComparisons,
    setShowComparisons,
    documentDimensions,
    UIFiltersInDimensions,
    UIFiltersInParameters,
    UIFiltersOutDimensions,
    UIFiltersOutParameters,
    reportDimensionsMetadata,
    onFiltersInUpdate,
    onFiltersOutUpdate,
    cutoffDate,
    setCutoffDate,
    reportParameters,
    disabled,
}) => {
    const { config } = useContext(AppContext);
    const configCutoffDate = config.time.cutoff_date;

    return (
        <Box
            datacy="customization_bar"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                px: 2,
                pt: 2,
                pb: 1,
                overflow: "auto",
            }}
        >
            <Grid container spacing={2} rowSpacing={2}>
                <Grid size={6}>
                    <ClickableDatePicker
                        dataCyProp="startDate_selector"
                        disabled={disabled}
                        required
                        views={["year", "month"]}
                        format="MMM YYYY"
                        label={config.i18n.procurement.project.start_date_column}
                        value={startDate || null}
                        minDate={globalMinDate}
                        maxDate={endDate}
                        onAccept={newStartDate => setStartDate(newStartDate.startOf("month").startOf("date"))}
                    />
                </Grid>
                <Grid size={6}>
                    <ClickableDatePicker
                        dataCyProp="endDate_selector"
                        disabled={disabled}
                        views={["year", "month"]}
                        format="MMM YYYY"
                        label={config.i18n.procurement.project.end_date_column}
                        value={endDate || null}
                        minDate={startDate}
                        maxDate={globalMaxDate}
                        onAccept={newEndDate => setEndDate(newEndDate.endOf("month").endOf("date"))}
                    />
                </Grid>
                <Grid size={6}>
                    <Autocomplete
                        label={config.i18n.selector.scenario}
                        forcePopupIcon
                        value={scenario || ""}
                        options={scenarioOptions || []}
                        getOptionLabel={option => option?.name || ""}
                        disabled={!isForecastingEnabled(config) || isEmpty(scenarioOptions) || disabled}
                        size="small"
                        onChange={(_, value) => setScenario(value)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                label={config.i18n.selector.scenario}
                                slotProps={{
                                    htmlInput: {
                                        ...params.inputProps,
                                        "value": scenario ? params.inputProps.value : "",
                                        "readOnly": true,
                                        "data-cy": "scenario_autocomplete",
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid size={6}>
                    <ClickableDatePicker
                        dataCyProp="cutoff_date_selector"
                        label={config.i18n.selector.cutoff_date}
                        views={["year", "month"]}
                        format="MMM YYYY"
                        disabled={!isForecastingEnabled(config) || !scenario || disabled || isEmpty(scenario)}
                        {...isForecastingEnabled(config) ? ({
                            disableFuture: true,
                            minDate: globalMinDate,
                            maxDate: configCutoffDate,
                            value: cutoffDate ? cutoffDate : globalMaxDate,
                            onAccept: newCutoffDate => setCutoffDate(newCutoffDate.startOf("month").startOf("date")),
                        }) : {}
                        }
                    />
                </Grid>
                <Grid size={12}>
                     <Autocomplete
                         label={config.i18n.report.multiplier.label}
                         forcePopupIcon
                         value={multiplierOptions.find(el => el.id === multiplier) || null}
                         options={multiplierOptions || []}
                         getOptionLabel={option => option?.label || ""}
                         disabled={disabled}
                         size="small"
                         onChange={(_, value) => {
                             setMultiplier(value?.id);
                         }}
                         renderInput={params => (
                             <TextField
                                 {...params}
                                 autoComplete="off"
                                 label={config.i18n.report.multiplier.label}
                                 slotProps={{ htmlInput: { ...params.inputProps, "readOnly": true, "data-cy": "multiplier_autocomplete" } }}
                             />
                         )}
                     />
                </Grid>
                <Grid container spacing={2} size={12}>
                    <Grid size={6}>
                        <Autocomplete
                            forcePopupIcon
                            value={referenceOptions.find(el => el.id === reference) || null}
                            options={referenceOptions || []}
                            getOptionLabel={option => option?.label || ""}
                            disabled={!isForecastingEnabled(config) || isEmpty(referenceOptions) || disabled}
                            size="small"
                            onChange={(_, value) => onReferenceChange(value)}
                            renderInput={params => (
                            <TextField
                                {...params}
                                autoComplete="off"
                                label={config.i18n.report.reference.label}
                                slotProps={{
                                    htmlInput: {
                                        ...params.inputProps,
                                        "value": reference ? params.inputProps.value : "",
                                        "readOnly": true,
                                        "data-cy": "reference_autocomplete",
                                    },
                                }}
                            />
                            )}
                        />
                    </Grid>
                    <Grid size={6}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    disabled={disabled || showComparisons === null}
                                    data-cy="show_comparisons"
                                    checked={!!showComparisons}
                                    onChange={event => setShowComparisons(event.target.checked)}
                                />
                            )}
                            label={config.i18n.selector.show_comparisons}
                        />
                    </Grid>
                </Grid>
                <Grid size={6}>
                    <FormControlLabel
                        disabled={disabled}
                        control={(
                            <Checkbox
                                data-cy="hide_empty_rows"
                                checked={hideRows}
                                onChange={event => setHideRows(event.target.checked)}
                            />
                        )}
                        label={config.i18n.selector.hide_rows}
                    />
                </Grid>
                <Grid size={6}>
                    <FormControlLabel
                        disabled={disabled}
                        control={(
                            <Checkbox
                                data-cy="hide_initial_months"
                                checked={hideInitialMonths}
                                onChange={event => setHideInitialMonths(event.target.checked)}
                            />
                        )}
                        label={config.i18n.selector.hide_initial_months}
                    />
                </Grid>
                <Grid size={12}>
                    <DimensionSelector
                        dataCyProp="filters-in-dim-selector"
                        title={config.i18n.customization_bar.filters_in}
                        disabled={disabled}
                        documentDimensions={documentDimensions}
                        dimensions={UIFiltersInDimensions}
                        dimensionFilters={UIFiltersInParameters}
                        filtersInParameters={UIFiltersInParameters}
                        filtersOutParameters={UIFiltersOutParameters}
                        metadata={reportDimensionsMetadata}
                        parameters={reportParameters}
                        isFiltersIn={true}
                        onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                            const newDimensions = union(UIFiltersInDimensions, [dimension]);

                            const newReportDimParameters = { ...UIFiltersInParameters };

                            // remove entry on dimension parameters, if the value is empty.
                            if (dimensionFilters !== null) {
                                Object.entries(dimensionFilters).forEach(([key, value]) => {
                                    if (isEmpty(value)) {
                                        delete newReportDimParameters[key];
                                    } else {
                                        newReportDimParameters[key] = value;
                                    }
                                });
                            }

                            // HACK: when a filter is set, existing filters out of that dimension should be removed
                            if (dimension in config.hierarchy && config.hierarchy[dimension].some(key => key in newReportDimParameters)) {
                                config.hierarchy[dimension].forEach((key) => {
                                    delete UIFiltersOutParameters[key];
                                });
                                onFiltersOutUpdate(UIFiltersOutDimensions, UIFiltersOutParameters, metadata);
                            } else if (dimension in newReportDimParameters) {
                                delete UIFiltersOutParameters[dimension];
                                onFiltersOutUpdate(UIFiltersOutDimensions, UIFiltersOutParameters, metadata);
                            }

                            onFiltersInUpdate(newDimensions, newReportDimParameters, metadata);
                        }}
                        onDelete={(dimension, dimensionKeys) => {
                            const newDimensions = without(UIFiltersInDimensions, dimension);
                            const newDimensionFilters = omit(UIFiltersInParameters, [dimension, ...dimensionKeys]);

                            onFiltersInUpdate(newDimensions, newDimensionFilters);
                        }}
                    />
                </Grid>
                <Grid size={12}>
                    <DimensionSelector
                        dataCyProp="filters-out-dim-selector"
                        title={config.i18n.customization_bar.filters_out}
                        disabled={disabled}
                        documentDimensions={documentDimensions}
                        dimensions={UIFiltersOutDimensions}
                        dimensionFilters={UIFiltersOutParameters}
                        filtersInParameters={UIFiltersInParameters}
                        filtersOutParameters={UIFiltersOutParameters}
                        metadata={reportDimensionsMetadata}
                        parameters={reportParameters}
                        isFiltersIn={false}
                        onUpdate={(dimension, dimensionFilters = null, metadata = null) => {
                            const newDimensions = union(UIFiltersOutDimensions, [dimension]);

                            const newReportDimParameters = { ...UIFiltersOutParameters };

                            // remove entry on dimension parameters, if the value is empty.
                            if (dimensionFilters !== null) {
                                Object.entries(dimensionFilters).forEach(([key, value]) => {
                                    if (isEmpty(value)) {
                                        delete newReportDimParameters[key];
                                    } else {
                                        newReportDimParameters[key] = value;
                                    }
                                });
                            }

                            onFiltersOutUpdate(newDimensions, newReportDimParameters, metadata);
                        }}
                        onDelete={(dimension, dimensionKeys) => {
                            const newDimensions = without(UIFiltersOutDimensions, dimension);
                            const newDimensionFilters = omit(UIFiltersOutParameters, [dimension, ...dimensionKeys]);

                            onFiltersOutUpdate(newDimensions, newDimensionFilters);
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

ReportCustomizationBar.propTypes = {
    startDate: PropTypes.object,
    setStartDate: PropTypes.func,
    endDate: PropTypes.object,
    setEndDate: PropTypes.func,
    globalMinDate: PropTypes.object,
    globalMaxDate: PropTypes.object,
    scenario: PropTypes.object,
    setScenario: PropTypes.func,
    scenarioOptions: PropTypes.array,
    multiplier: PropTypes.string,
    setMultiplier: PropTypes.func,
    multiplierOptions: PropTypes.object,
    hideRows: PropTypes.bool,
    setHideRows: PropTypes.func,
    hideInitialMonths: PropTypes.bool,
    setHideInitialMonths: PropTypes.func,
    reference: PropTypes.string,
    onReferenceChange: PropTypes.func,
    referenceOptions: PropTypes.object,
    showComparisons: PropTypes.bool,
    setShowComparisons: PropTypes.func,
    documentDimensions: PropTypes.object,
    UIFiltersInDimensions: PropTypes.array,
    UIFiltersInParameters: PropTypes.object,
    UIFiltersOutDimensions: PropTypes.array,
    UIFiltersOutParameters: PropTypes.object,
    reportDimensionsMetadata: PropTypes.object,
    onFiltersInUpdate: PropTypes.func,
    onFiltersOutUpdate: PropTypes.func,
    cutoffDate: PropTypes.object,
    setCutoffDate: PropTypes.func,
    reportParameters: PropTypes.object,
    disabled: PropTypes.bool,
};

export default ReportCustomizationBar;
