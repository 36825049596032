import { Alert, Box, Button, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import AdvisorContainer from "../../layout/AdvisorContainer";
import SelectField from "../../components/field/SelectField";
import CircularLoading from "../../components/loading/CircularLoading";
import { Link as RouterLink, useParams } from "react-router-dom";
import { formatDecimal, formatRatioToPercentage } from "../../util/formatter";
import Markdown from "markdown-to-jsx";
import { AppContext } from "../../AppRouter";
import ClickableDatePicker from "../../components/ClickableDatePicker";
import { listScenarioSeries, listScenarioTemplateSeries } from "../../util/client";

const ScenarioDetails = ({ operation, title, button, icon, shouldValidate, loading, hasTemplate, onClick }) => {
    const { client, config, notify, user } = useContext(AppContext);

    const [name, setName] = useState("");
    const [templateOptions, setTemplateOptions] = useState(null);
    const [template, setTemplate] = useState(null);
    const [series, setSeries] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [permission, setPermission] = useState({});

    const { scenarioId } = useParams();

    const columns = [
        {
            field: "name",
            headerName: config.i18n.scenario.name_series,
            flex: 4,
        },
        {
            field: "method",
            headerName: config.i18n.scenario.method_series,
            flex: 2,
        },
        {
            field: "growth",
            headerName: config.i18n.scenario.growth_series,
            flex: 2,
        },
        {
            field: "target_value",
            headerName: config.i18n.scenario.target_value,
            flex: 2,
        },
        {
            field: "target_date",
            headerName: config.i18n.scenario.target_date,
            flex: 2,
        },
    ];

    useEffect(() => {
        if (scenarioId) {
            listScenarioSeries(client, scenarioId)
                .then((scenarioSeries) => {
                    setName(scenarioSeries.name);
                    setSeries(scenarioSeries.series);
                    setPermission(scenarioSeries.permission);
                    setShowTable(true);
                })
                .catch((error) => {
                    setSeries([]);
                    notify.error(error, "scenario.template.fetch");
                });
        }
    }, [scenarioId]);

    useEffect(() => {
        if (template) {
            listScenarioTemplateSeries(client, template.id)
                .then((templateSeries) => {
                    const newSeries = setSeriesDefaultValues(templateSeries);
                    setSeries(newSeries);
                })
                .catch((error) => {
                    setSeries([]);
                    notify.error(error, "scenario.template.fetch");
                });
        }
    }, [template]);

    useEffect(() => {
        if (user.permissions?.FORECAST?.CREATE) {
            client.scenario.scenarioListScenarioTemplates()
                .then((templates) => {
                    setTemplateOptions(templates);
                })
                .catch((error) => {
                    setTemplateOptions([]);
                    notify.error(error, "scenario.template.fetch");
                });
        } else {
            setTemplateOptions([]);
        }
    }, []);

    if (!templateOptions) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.scenario.list.loading} />
            </AdvisorContainer>
        );
    }

    const setSeriesDefaultValues = (series) => {
        return series.map((singleSeries) => {
            const newSingleSeries = { ...singleSeries };

            if (newSingleSeries.target_value == null) {
                newSingleSeries.target_value = 0; // TODO: Fetch last series value #6619
            }

            if (newSingleSeries.target_date == null) {
                newSingleSeries.target_date = config.time.global.max;
            }

            if (newSingleSeries.yearly_growth == null) {
                newSingleSeries.yearly_growth = 0;
            }

            return newSingleSeries;
        });
    };

    const handleChangeSeriesMethod = (row, event) => {
        row.method = event;

        setSeries([...series]);
    };

    const handleChangeSeriesGrowth = (row, event) => {
        row.yearly_growth = Number(event.target.value / 100);

        setSeries([...series]);
    };

    const handleChangeTargetValue = (row, event) => {
        row.target_value = Number(event.target.value);

        setSeries([...series]);
    };

    const handleChangeTargetDate = (row, date) => {
        row.target_date = date;
    };

    const permissionsDisableComponent = () => {
        switch (operation) {
            case "clone":
            case "create":
                return !user.permissions?.FORECAST?.CREATE;
            case "edit":
                return !user.permissions?.FORECAST?.UPDATE && !permission?.UPDATE;
        }
    };

    const isAbsoluteDelta = (series) => {
        return series.method === "ABSOLUTE_DELTA";
    };

    const getCellValue = (row, column) => {
        switch (column.field) {
            case "name":
                return (
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                        <Tooltip title={row.name}>
                            {row.name}
                        </Tooltip>
                    </div>
                );
            case "method":
                return row.forecasting_type === "EXTRAPOLATED"
                    ? (
                        <SelectField
                            key="method-selector"
                            dataCyProp={`scenario_series-${row.name}-method_selector`}
                            disabled={permissionsDisableComponent()}
                            value={row[column.field]}
                            metadata={config.i18n.scenario.method}
                            possibleValues={row.available_methods}
                            onChange={event => handleChangeSeriesMethod(row, event)}
                            useMarkdown
                        />
                        )
                    : (
                        <Alert severity="info" sx={{ whiteSpace: "pre-line" }}>
                            <Markdown>
                                {row.description || ""}
                            </Markdown>
                        </Alert>
                        );
            case "growth":
                return row.forecasting_type === "EXTRAPOLATED"
                    ? (
                        <TextField
                            size="small"
                            type="number"
                            data-cy={`scenario_series-${row.name}-growth`}
                            disabled={permissionsDisableComponent() || isAbsoluteDelta(row)}
                            slotProps={{
                                input: {
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                },
                            }}
                            placeholder={config.i18n.scenario.growth_series}
                            value={!isAbsoluteDelta(row) ? formatRatioToPercentage(row.yearly_growth, config.locale, false, 7) : ""}
                            autoComplete="off"
                            onChange={event => handleChangeSeriesGrowth(row, event)}
                        />
                        )
                    : null;
            case "target_value":
                return row.forecasting_type === "EXTRAPOLATED"
                    ? (
                        <TextField
                            size="small"
                            type="number"
                            data-cy={`scenario_series-${row.name}-target_value`}
                            disabled={permissionsDisableComponent() || !isAbsoluteDelta(row)}
                            placeholder={config.i18n.scenario.target_value}
                            value={isAbsoluteDelta(row) ? formatDecimal(row.target_value, config.locale, 0, false) : ""}
                            onChange={event => handleChangeTargetValue(row, event)}
                        />
                        )
                    : null;
            case "target_date":
                return row.forecasting_type === "EXTRAPOLATED"
                    ? (
                        <ClickableDatePicker
                            required
                            format="MMM YYYY"
                            views={["year", "month"]}
                            disabled={permissionsDisableComponent() || !isAbsoluteDelta(row)}
                            dataCyProp={`scenario_series-${row.name}-target_date`}
                            placeholder={config.i18n.scenario.target_date}
                            value={isAbsoluteDelta(row) ? row.target_date : null}
                            onAccept={date => handleChangeTargetDate(row, date.startOf("month").startOf("date"))}
                            minDate={config.time.cutoff_date}
                            maxDate={config.time.global.max}
                        />
                        )
                    : null;
            default:
                return row[column.field];
        }
    };

    const scenarioDetailsReady = showTable && series.length;

    return (
        <AdvisorContainer
            title={title}
        >
            <Box
                component="form"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <TextField
                    required
                    fullWidth
                    disabled={permissionsDisableComponent()}
                    size="small"
                    data-cy="scenario_details-name"
                    error={shouldValidate ? !name : null}
                    helperText={shouldValidate && !name ? config.i18n.warn.field_empty : ""}
                    label={config.i18n.scenario.list.name_column}
                    value={name}
                    autoComplete="off"
                    onChange={e => setName(e.target.value)}
                    sx={{ my: 1, mb: 2 }}
                />
                {hasTemplate
                    ? (
                        <SelectField
                            key="template-selector"
                            dataCyProp="scenario_details-template_selector"
                            label={config.i18n.scenario.template}
                            value={template || ""}
                            possibleValues={templateOptions || []}
                            onChange={(e) => {
                                setTemplate(e);
                                setShowTable(true);
                            }}
                            sx={{ flex: 1, mb: 2 }}
                        />
                        )
                    : null}
                { template
                    ? (
                        <Alert severity="info" style={{ width: "100%" }} sx={{ mb: 2 }}>
                            <Markdown>
                                {template.description}
                            </Markdown>
                        </Alert>
                        )
                    : null}
            </Box>
            {scenarioDetailsReady
                ? (
                    <Box sx={{ mb: 2 }}>
                        <TableContainer>
                            <Table size="small" sx={{ tableLayout: "fixed" }} aria-label="spanning table">
                                <TableHead>
                                    <TableRow sx={{ wordBreak: "break-word" }}>
                                        {columns.map((column, index) => (
                                            <TableCell key={column.field} width={`${[28, 50, 14, 17, 19][index]}%`}>
                                                {column.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {series.filter(row => !row.hidden)
                                        .map((row, rowIndex) => (
                                            <TableRow
                                                key={rowIndex}
                                                data-cy={`scenario_series-${row.name}`}
                                                sx={{ wordBreak: "break-word" }}
                                            >
                                                {columns.map((column, colIndex) => (
                                                    <TableCell
                                                        key={column.field}
                                                        style={{ whiteSpace: "nowrap" }}
                                                        width={`${[28, 50, 14, 17, 19][colIndex]}%`}
                                                        colSpan={row.forecasting_type !== "EXTRAPOLATED" && colIndex === 1 ? 4 : 1}
                                                    >
                                                        {getCellValue(row, column)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    )
                : null}
            <Box
                sx={{
                    display: "flex",
                }}
            >
                <Button
                    variant="contained"
                    color="grey"
                    disabled={loading}
                    sx={{ mr: 1 }}
                    title={config.i18n.button.cancel}
                    component={RouterLink}
                    to="/scenario"
                >
                    {config.i18n.button.cancel}
                </Button>
                <Button
                    variant="contained"
                    loading={loading}
                    loadingPosition="start"
                    data-cy="scenario_details-save_button"
                    startIcon={icon}
                    title={button}
                    disabled={!scenarioDetailsReady || (permissionsDisableComponent())}
                    onClick={() => onClick(name, series, template)}
                >
                    {button}
                </Button>
            </Box>
        </AdvisorContainer>
    );
};

ScenarioDetails.propTypes = {
    operation: PropTypes.string,
    title: PropTypes.string,
    button: PropTypes.any,
    icon: PropTypes.any,
    shouldValidate: PropTypes.bool,
    loading: PropTypes.bool,
    hasTemplate: PropTypes.bool,
    onClick: PropTypes.func,
};

export default ScenarioDetails;
