import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ClickableDataGrid from "../../components/ClickableDataGrid";
import { formatDate, formatDateTime } from "../../util/formatter";
import CircularLoading from "../../components/loading/CircularLoading";
import AdvisorContainer from "../../layout/AdvisorContainer";
import { AppContext } from "../../AppRouter";
import WarningIcon from "@mui/icons-material/Warning";

const Procurement = () => {
    const { client, config, notify } = useContext(AppContext);
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [toDelete, setToDelete] = useState();
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);

    const columns = [
        {
            field: "name",
            headerName: config.i18n.procurement.project.name_column,
            flex: 4,
            sortable: false,
        },
        {
            field: "status",
            headerName: config.i18n.procurement.project.status,
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                let statusText = "";
                let textColorStyle = {};

                switch (params.value) {
                    case "ERROR":
                        statusText = config.i18n.procurement.project.error;
                        textColorStyle = { color: "red" };
                        break;
                    case "NOT_READY":
                    case "RUNNING":
                        statusText = config.i18n.procurement.project.calculating;
                        textColorStyle = { color: "red" };
                        break;
                    case "READY":
                        statusText = config.i18n.procurement.project.ready;
                        textColorStyle = { color: "green" };
                        break;
                    case "READY_WITH_ERROR":
                        statusText = config.i18n.procurement.project.ready_with_error;
                        textColorStyle = { color: "orange" };
                        break;
                    default:
                        statusText = params.value;
                        break;
                }
                return <span style={textColorStyle}>{statusText}</span>;
            },
        },
        {
            field: "created_at",
            headerName: config.i18n.procurement.project.created_at_column,
            flex: 2,
            sortable: false,
            renderCell: params => (
                <span title={`${config.i18n.collector.created_at} ${formatDateTime(params.value, config.locale)} by ${params.row.created_by_name} `}>
                    {formatDate(params.value, config.locale)}
                </span>
            ),
        },
        {
            field: "actions",
            flex: 0,
            sortable: false,
            type: "actions",
            getActions: params => [
                <Tooltip key="edit" title={config.i18n.button.edit}>
                    <>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label={config.i18n.button.edit}
                            color="info"
                            data-cy="edit"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            component={RouterLink}
                            to={`/procurement/project/${params.id}/edit`}
                            disabled={!isProjectReady(params.row.status)}
                        />
                    </>
                </Tooltip>,
                <Tooltip key="delete" title={config.i18n.button.delete}>
                    <>
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label={config.i18n.button.delete}
                            color="error"
                            data-cy="delete"
                            onClick={(e) => {
                                e.stopPropagation();
                                setToDelete(params.row);
                            }}
                            disabled={isProjectRunning(params.row.status)}
                        />
                    </>
                </Tooltip>,
            ],
        },
    ];

    const isProjectReady = (status) => {
        return status === "READY" || status === "READY_WITH_ERROR";
    };

    const isProjectRunning = (status) => {
        return status === "RUNNING";
    };

    const handleRowClick = (params) => {
        if (!isProjectReady(params.row.status)) {
            return;
        }
        navigate(`/procurement/project/${params.id}/prioritize`);
    };

    useEffect(() => {
        // Check if there are projects with a status other than "READY"
        const hasPendingProjects = projects.some(project => (isProjectRunning(project.status)));
        const timeout = 5000; // 5 seconds

        if (hasPendingProjects) {
            const interval = setInterval(() => {
                client.procurement.procurementListProjects()
                    .then((projects) => {
                        setProjects(projects.response);
                        setLoading(false);
                    })
                    .catch((error) => {
                        setProjects([]);
                        setLoading(false);
                        notify.error(error, "procurement.project.projects.fetch");
                    });
            }, timeout);

            return () => clearInterval(interval);
        }
    }, [projects]);

    useEffect(() => {
        client.procurement.procurementListProjects()
            .then((projects) => {
                setProjects(projects.response);
                setLoading(false);
            })
            .catch((error) => {
                setProjects([]);
                setLoading(false);
                notify.error(error, "procurement.project.projects.fetch");
            });
    }, []);

    if (loading) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.procurement.project.loading} />
            </AdvisorContainer>
        );
    }

    return (
        <AdvisorContainer
            title={config.i18n.page.procurement}
            actions={(
                <Button
                    data-cy="create"
                    variant="contained"
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to="/procurement/project/create"
                    title={config.i18n.button.create}
                >
                    {config.i18n.button.create}
                </Button>
            )}
        >
            {
                toDelete
                    ? (
                        <Dialog
                            maxWidth="xs"
                            open={!!toDelete}
                        >
                            <DialogTitle>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <WarningIcon color="warning" sx={{ marginRight: 1 }} />
                                    {config.i18n.dialog.delete_project_title}
                                </Box>
                            </DialogTitle>
                            <DialogContent dividers>
                                {config.i18n.dialog.delete_project + " " + toDelete.name + "?"}
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={deleting} onClick={() => setToDelete(null)}>
                                    {config.i18n.button.cancel}
                                </Button>
                                <Button
                                    loading={deleting}
                                    loadingPosition="center"
                                    onClick={() => {
                                        setDeleting(true);
                                        client.procurement.procurementDeleteProject(toDelete.id)
                                            .then((project) => {
                                                setProjects(projects.filter(el => el.id !== project.id));
                                                setDeleting(false);
                                                setToDelete(null);
                                            })
                                            .catch((error) => {
                                                notify.error(error, "procurement.project.projects.delete");
                                                setDeleting(false);
                                                setToDelete(null);
                                            });
                                    }}
                                    data-cy="procurement_dialog-delete"
                                >

                                    {config.i18n.button.delete}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        )
                    : null
            }
            <ClickableDataGrid
                dataCy="procurements_list"
                rows={projects}
                columns={columns}
                onRowClick={handleRowClick}
                getRowClassName={(params) => {
                    return isProjectRunning(params.row.status) ? "non-clickable-row" : "";
                }}
            />
        </AdvisorContainer>
    );
};

export default Procurement;
