import { formatCompact, getCompactFormatter } from "../../util/formatter";
import theme from "../../theme/theme";
import { getAxisLabel } from "./formatter";
import { upperFirstAlphanumericLetter } from "../../util/string";

export const getXData = (data) => {
    return data.result.map(el => el.label);
};

export const getCategoricalAxisOptions = (name, data, metadata, config, baseFontSize, extraColumns) => {
    return {
        data: data,
        name: upperFirstAlphanumericLetter(name),
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "category",
        axisLabel: {
            interval: 0, // TODO dynamic interval
            rotate: 0,
            width: baseFontSize * (15 / (data.length + (extraColumns || 0))),
            height: 2 * 0.35 * baseFontSize,
            margin: 0.25 * baseFontSize,
            overflow: "breakAll",
            lineOverflow: "truncate", // TODO this currently requires an echarts patch
            fontSize: defaultLabelFontSize(baseFontSize),
            lineHeight: 0.35 * baseFontSize,
            formatter: value => getAxisLabel(value, metadata, config),
        },
    };
};

export const getNumericalAxisOptions = (name, data, config, label_type, baseFontSize) => {
    return {
        name: upperFirstAlphanumericLetter(name),
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "value",
        axisLabel: {
            fontSize: defaultLabelFontSize(baseFontSize),
            formatter: getCompactFormatter(data, config.locale, config.i18n, label_type === "PERCENTAGE", formatCompact),
            rich: {
                min: {
                    color: theme.palette.error.main,
                    borderWidth: 1,
                    borderColor: theme.palette.error.main,
                    borderRadius: 5,
                    borderType: "dotted",
                    padding: 5,
                    fontSize: 0.38 * baseFontSize,
                },
            },
        },
    };
};

export const getLegend = (data, config, baseFontSize) =>
    ({
        width: "64%", // Reduce chart width to give space for legend.
        legend: {
            selectedMode: false,
            orient: "vertical",
            top: "middle",
            left: "78%", // relative to container width. 78% is just enough next to the slide.
            icon: "roundRect",
            itemGap: 0.2 * baseFontSize,
            itemWidth: 0.4 * baseFontSize,
            itemHeight: 0.4 * baseFontSize,
            textStyle: {
                fontSize: 0.38 * baseFontSize,
                color: theme.palette.grey["600"],
                overflow: "truncate",
            },
            formatter: function (id) {
                return getAxisLabel(id, data.metadata, config);
            },
        },
    });

export const getMaxLabelLength = (data, config) => {
    const labels = data.result.map(el => getAxisLabel(el.x, data.metadata, config));
    const lengths = labels.map(el => el.length);
    return Math.max(...lengths);
};

export function getLineColor(config, param) {
    // FIXME 2868: change this to use color scheme colors
    switch (param) {
        case "MEDIAN":
            return theme.palette.echartsYellow.main;
        case "P75":
            return theme.palette.echartsOrange.main;
        case "AVERAGE":
            return theme.palette.echartsGreen.main;
        case "P25":
            return theme.palette.echartsRed.main;
    }
}

export function displayStatLines(statLinesParam, statLinesValue, config) {
    return statLinesParam
        // ignore params not present in the calculated value.
        .filter(param => statLinesValue && param in statLinesValue)
        .map((param) => {
            return {
                name: config.i18n.chart.lines[param],
                yAxis: statLinesValue[param],
                lineStyle: {
                    color: getLineColor(config, param),
                },
            };
        },
        );
}

export const defaultLabelFontSize = (baseFontSize) => {
    return 0.35 * baseFontSize;
};
