import React, { useContext } from "react";
import { dimensionSearchAll } from "../../../util/client";
import { AppContext } from "../../../AppRouter";
import AutoCompleteBox from "../../../components/picker/AutoCompleteBox";
import { Paper } from "@mui/material";

const dispatchDimensionSearch = (client, dimension, queryString, page = 0) => {
    return dimensionSearchAll(client, queryString, dimension, page);
};

export const DimensionCellTemplate = {
    getCompatibleCell(uncertainCell) {
        return {
            ...uncertainCell,
            disabled: uncertainCell.disabled,
            value: uncertainCell.value,
        };
    },
    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
    },
    render(
        cell,
        isInEditMode,
        onCellChanged,
    ) {
        const { client } = useContext(AppContext);
        const CustomPaper = (props) => {
            // Without this, it's not possible to select a dropdown option
            return <Paper elevation={8} {...props} onPointerDown={e => e.stopPropagation()} />;
        };

        return (
            <AutoCompleteBox
                fullWidth
                value={cell.value}
                PaperComponent={CustomPaper}
                updateOnSelect
                dimension={cell.dimension}
                multiple={false}
                onChange={(dimValues) => {
                    onCellChanged(
                        this.getCompatibleCell({ ...cell, value: dimValues ? { name: dimValues.name, id: dimValues.id } : null }),
                        true,
                    );
                }}
                inputPropsParams={{
                    disableUnderline: true,
                }}
                textFieldParams={{
                    variant: "standard",
                    onKeyDown: (e) => { // Without this, the backspace doesn't work
                        e.stopPropagation();
                    },
                }}
                onQuery={(queryString, page = 0) => dispatchDimensionSearch(client, cell.dimension, queryString, page)}
                sx={{
                    "& .MuiInputBase-input": {
                        padding: "0px !important", // Fixes the Autocomplete padding inside the cell
                    },
                    "& .MuiInputBase-root": {
                        fontSize: "1em", // Matches default cell style
                    },
                }}
            >
            </AutoCompleteBox>
        );
    },
};

export const getDimensionCell = (dimension, dimension_id, metadata, disabled = false) => ({
    type: "dimension",
    value: dimension_id ? { name: metadata[dimension_id].name, id: dimension_id } : null,
    disabled,
    dimension,
});
