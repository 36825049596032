import React, { useContext } from "react";
import { Box, Paper, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { Dimension } from "../../client";
import DimensionFilters from "../../components/DimensionFilters";
import DeleteIconButton from "../../components/button/DeleteIconButton";
import { AppContext } from "../../AppRouter";
import { Stack } from "@mui/system";

const Scope = ({ scope, scopeMetadata, parameters, disabled, shouldValidate, onUpdate }) => {
    const { config } = useContext(AppContext);

    return (
        <>
            <Typography variant="h6">{config.i18n.procurement.scope.title}</Typography>
            <Box sx={{ my: 2 }}>
                <Paper sx={{ padding: 2 }}>
                    <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                        <TextField
                            label={config.i18n.procurement.scope.name_label}
                            required
                            disabled={disabled}
                            error={shouldValidate ? !scope.name : null}
                            helperText={shouldValidate && !scope.name
                                ? config.i18n.warn.field_empty
                                : ""}
                            placeholder={config.i18n.procurement.scope.filter_name_placeholder}
                            variant="outlined"
                            size="small"
                            value={scope.name}
                            autoComplete="off"
                            onClick={e => e.stopPropagation()}
                            onChange={(e) => {
                                onUpdate({
                                    ...scope,
                                    name: e.target.value,
                                });
                            }}
                            data-cy="filter_name_field"
                        />
                        {
                            scope && !disabled
                                ? (
                                    <DeleteIconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onUpdate(null);
                                        }}
                                    />
                                    )
                                : null
                        }
                    </Stack>
                    <DimensionFilters
                        dimensionParameters={scope.filters_in}
                        onUpdate={(filters, updatedMetadata) => {
                            const newMetadata = { ...scopeMetadata, ...updatedMetadata };
                            onUpdate({
                                id: scope.id,
                                name: scope.name,
                                filters_in: filters,
                            }, newMetadata);
                        }}
                        customizationParameters={
                            [
                                {
                                    type: "DIMENSION",
                                    dim: Dimension.VENDOR,
                                },
                                {
                                    type: "DIMENSION",
                                    dim: Dimension.OWNER,
                                },
                                {
                                    type: "HIERARCHY",
                                    dim: Dimension.ORG_UNIT,
                                },
                                {
                                    type: "HIERARCHY",
                                    dim: Dimension.CATEGORY,
                                },
                            ]
                        }
                        parameters={parameters}
                        metadata={scopeMetadata}
                        disabled={disabled}
                    />
                </Paper>
            </Box>
        </>
    );
};

Scope.propTypes = {
    scope: PropTypes.array,
    scopeMetadata: PropTypes.object,
    parameters: PropTypes.object,
    disabled: PropTypes.bool,
    shouldValidate: PropTypes.bool,
    onUpdate: PropTypes.func,
};

export default Scope;
