import { formatTemplate, formatValue } from "../../util/formatter";

export function getFormatterFromLabelType(labelType, compact) {
    switch (labelType) {
        case "INTEGER":
            return compact ? "compactinteger" : "integer";
        case "DECIMAL":
            return compact ? "compact" : "decimal";
        case "AMOUNT":
            return compact ? "compactamount" : "amount";
        case "DATE":
            return "date";
        case "PERCENTAGE":
            return "percentage";
        default:
            return null;
    }
}

export function getFormattedValue(val, labelType, config, compact = true) {
    return getFormatter(labelType, config, compact)(val);
}

export function getFormatter(labelType, config, compact) {
    const formatterType = getFormatterFromLabelType(labelType, compact);

    if (formatterType) {
        return value => formatValue(value, formatterType, config.locale, config.i18n);
    } else {
        return value => value;
    }
}

/**
 * Gets the axis label for a given ID.
 * The label will be picked based on precedence: short name, name, chart label, document label, accounting treatment label and ID.
 *
 * @param {*|string} id dimension ID, chart ID, document id or accounting treatment ID
 * @param metadata dimensions' metadata
 * @param config config
 */
export const getAxisLabel = (id, metadata, config) => {
    if (id === null) {
        return config.i18n.chart.label["__null__"];
    }

    // if there is a template, apply it
    if (metadata[id]?.template) {
        return formatTemplate(metadata[id]?.template, {}, config.locale, config.i18n);
    }

    return metadata[id]?.short_name
        || metadata[id]?.name
        || config.i18n.chart.label[id]
        || config.i18n.Document[id]
        || config.i18n.AccountingTreatment[id]
        || id;
};

/**
 * Gets the tooltip label for a given ID.
 * The label will be picked based on precedence: name, short name, chart label, document label, accounting treatment label and ID.
 *
 * @param {*|string} id dimension ID, chart ID, document id or accounting treatment ID
 * @param metadata dimensions' metadata
 * @param config config
 */
export const getTooltipLabel = (id, metadata, config) => {
    if (id === null) {
        return config.i18n.chart.label["__null__"];
    }

    // if there is a template, apply it
    if (metadata[id]?.template) {
        return formatTemplate(metadata[id]?.template, {}, config.locale, config.i18n);
    }

    return metadata[id]?.name
        || metadata[id]?.short_name
        || config.i18n.chart.label[id]
        || config.i18n.Document[id]
        || config.i18n.AccountingTreatment[id]
        || id;
};
