import { getDropdownCell } from "./cells/DropdownCell";
import { HammerMeasure } from "../../client";
import { getDimensionCell } from "./cells/DimensionCell";
import { getAddButtonCell } from "./cells/AddButtonCell";
import { getDeleteButtonCell } from "./cells/DeleteButtonCell";

export const transformHammersToRows = (hammers, dimensionColumns, dateColumns, onPressDeleteButton, config) => {
    return hammers.map(hammer => ({
        rowId: hammer.id,
        cells: [
            { type: "checkbox", checked: hammer.enabled },
            { type: "text", text: hammer.name },
            getDropdownCell(config.i18n.DocumentType[hammer.document_type] || "", hammer.document_type, config.hammer.document_types, config.i18n.DocumentType),
            getDropdownCell(config.i18n.AccountingTreatment[hammer.accounting_treatment] || "", hammer.accounting_treatment, config.hammer.accounting_treatments, config.i18n.AccountingTreatment),
            getDropdownCell(config.i18n.hammer.Measures[hammer.measure] || "", hammer.measure, Object.values(HammerMeasure), config.i18n.hammer.Measures),
            ...dimensionColumns.map(dimension => (
                getDimensionCell(dimension, dimension in hammer.dimensions ? hammer.dimensions[dimension] : "", hammer.metadata)
            )),
            ...dateColumns.map(date => ({
                type: "number",
                value: hammer.values[date] ?? null,
            })),
            getDeleteButtonCell(hammer.id, onPressDeleteButton),
        ],
    }));
};

export const getEmptyCell = () => ({ type: "text", text: "", editable: false });

export const getBlankRow = (onPressButton, id, numberOfCells) => ({
    rowId: id ? id : 0,
    cells: [
        getAddButtonCell(onPressButton),
        ...Array(numberOfCells - 1).fill(getEmptyCell("")),
    ],
});
