import { getForecastingLine, getFormatterFromDateAgg, getTimeSeriesAxisOptions } from "./common";
import { getLegend, getNumericalAxisOptions } from "../common";
import { formatValue } from "../../../util/formatter";
import { sum } from "lodash";
import { getFormattedValue, getFormatter, getTooltipLabel } from "../formatter";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    const seriesArray = data.series_labels.map((label, index) => ({
        name: data.series_labels[index],
        type: "line",
        stack: "y",
        areaStyle: {},
        triggerLineEvent: true,
        symbolSize: 0.15 * baseFontSize,
        lineStyle: { width: 0.07 * baseFontSize },
        data: data.result.map(entry => [entry.x.toDate(), entry.y[index]]),
        ...getForecastingLine(data, config, baseFontSize),
    }));

    // Display the total of each stack
    // The way it is displayed depends on whether positive or negative values are present
    const allYValues = data.result.map(entry => entry.y.map(value => value)).flat(2);
    if (seriesArray.length === 1 || allYValues.every(value => value <= 0) || allYValues.every(value => value >= 0)) {
        seriesArray[seriesArray.length - 1].label = { // Only show label for the total sum of the stack
            show: true,
            position: "top",
            fontSize: 0.3 * baseFontSize,
            formatter: params => getFormattedValue(sum(data.result[params.dataIndex].y), data.label_type.y, config),
        };
    } else {
        const totalSeries = {
            name: "Total",
            type: "line",
            data: data.result.map(entry => [entry.x.toDate(), sum(entry.y)]),
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.value[1], data.label_type.y, config),
            },
            symbolSize: 0.15 * baseFontSize,
            lineStyle: { width: 0.07 * baseFontSize },
        };
        seriesArray.unshift(totalSeries); // Place this series at the beginning of the array, so that the line has always the same color no matter what
    }

    return {
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 0.4 * baseFontSize },
            formatter: (params) => {
                let dateFormatter = getFormatterFromDateAgg(data.parameters["DATE_AGG"]);
                const valueFormatter = getFormatter(data.label_type.y, config);

                // Start of the tooltip is always the date
                let text = formatValue(params[0].value[0], dateFormatter, config.locale, config.i18n, data.metadata);

                params.forEach((series) => {
                    if (series.value[1] !== Number.EPSILON) {
                        text += `<br/> ${series.marker} ${getTooltipLabel(series.seriesName, data.metadata, config)} : ${valueFormatter(series.value[1])}`;
                    }
                });

                return text;
            },
        },
        ...getLegend(data, config, baseFontSize),
        series: seriesArray,
    };
}
