import React from "react";
import { MenuItem, Select } from "@mui/material";

export const DropdownCellTemplate = {
    getCompatibleCell(uncertainCell) {
        return {
            ...uncertainCell,
            text: uncertainCell.text || "",
            value: uncertainCell.value || "",
            values: uncertainCell.values || [],
            disabled: uncertainCell.disabled,
        };
    },
    update(cell, cellToMerge) {
        return this.getCompatibleCell({
            ...cell,
            text: cell.translation[cellToMerge.value] || "",
            value: cellToMerge.value,
        });
    },
    render(cell, isInEditMode, onCellChanged) {
        return (
            <Select
                value={cell.value}
                fullWidth
                variant="standard"
                disableUnderline
                onChange={(e) => {
                    onCellChanged(
                        this.getCompatibleCell({ ...cell, value: e.target.value }),
                        true,
                    );
                }}
                style={{
                    fontSize: "1em", // Ensures same font size as ReactGrid cells
                }}
            >
                {cell.values.map((value, idx) => (
                    <MenuItem key={idx} value={value}>
                        {cell.translation[value]}
                    </MenuItem>
                ))}
            </Select>
        );
    },
};

export const getDropdownCell = (text, value, values, translation, disabled = false) => ({
    type: "mui_dropdown",
    text,
    value,
    values: values.sort(),
    translation,
    disabled,
});
