"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelType = void 0;
var LabelType;
(function (LabelType) {
    LabelType["INTEGER"] = "INTEGER";
    LabelType["DECIMAL"] = "DECIMAL";
    LabelType["AMOUNT"] = "AMOUNT";
    LabelType["DATE"] = "DATE";
    LabelType["PERCENTAGE"] = "PERCENTAGE";
})(LabelType || (exports.LabelType = LabelType = {}));
