import { Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import ClickableDataGrid from "../../../components/ClickableDataGrid";
import CircularLoading from "../../../components/loading/CircularLoading";
import { formatCompactAmount, formatInteger, formatRatioToPercentage, getCompactFormatter } from "../../../util/formatter";
import Wizard from "../../../components/Wizard";
import { validateProject } from "./util";
import EditableTextField from "../../../components/field/EditableTextField";
import FullscreenLoading from "../../../components/loading/FullscreenLoading";
import AdvisorContainer from "../../../layout/AdvisorContainer";
import ReadOnlyTextField from "../../../components/field/ReadOnlyTextField";
import ClickableDatePicker from "../../../components/ClickableDatePicker";
import { AppContext } from "../../../AppRouter";
import { Stack } from "@mui/system";
import { getProject, scheduleInitiatives } from "../../../util/client";

const Schedule = ({ steps, basePath, originPath }) => {
    const { client, config, notify } = useContext(AppContext);
    const { projectId } = useParams();

    const [projectName, setProjectName] = useState();
    const [projectStatus, setProjectStatus] = useState({});

    const [prioritizedInitiatives, setPrioritizedInitiatives] = useState();
    const [initiativeDates, setInitiativeDates] = useState({});

    const [hasChanges, setHasChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const [previewing, setPreviewing] = useState(false);

    const [monthlyRunRates, setMonthlyRunRates] = useState([]);
    const [npv, setNpv] = useState(0);

    const [implementationStartDate, setImplementationStartDate] = useState();

    // owners
    const [userData, setUserData] = useState();

    const [showDetails, setShowDetails] = useState(false);

    const parseAndSetInitiativeDates = (initiatives) => {
        const newInitiativeDates
            = initiatives.reduce((previous, current) =>
                ({
                    ...previous,
                    [current.id]: current.start_month,
                }), {});

        setInitiativeDates(newInitiativeDates);
    };

    const sortPrioritizedInitiatives = (project) => {
        return project.initiatives
            .filter(initiative => initiative.identified && initiative.prioritized)
            .sort((initiative1, initiative2) =>
                initiative1.schedule_index - initiative2.schedule_index);
    };

    const setProject = (project) => {
        setProjectName(project.name);
        setImplementationStartDate(project.implementation_start_date);

        const prioritized = sortPrioritizedInitiatives(project);
        setPrioritizedInitiatives(prioritized);
        parseAndSetInitiativeDates(prioritized);

        const ownerIds = prioritized
            .flatMap(initiative => [initiative.business_owner, initiative.procurement_owner])
            .filter(ids => !!ids);

        client.user.userGetUsers(ownerIds)
            .then((users) => {
                setUserData(users);
            })
            .catch((error) => {
                setUserData({});
                notify.error(error, "user.not.found");
            });

        setMonthlyRunRates(project.monthly_run_rates);
        setNpv(project.npv);
    };

    const setNullProject = () => {
        setProjectName(null);
        setImplementationStartDate(null);

        setPrioritizedInitiatives([]);
        parseAndSetInitiativeDates([]);

        setMonthlyRunRates([]);
        setNpv(0);
    };

    const generatePayloadForSchedule = () => {
        return prioritizedInitiatives.map((initiative) => {
            return {
                id: initiative.id,
                start_month: initiativeDates[initiative.id],
            };
        });
    };

    const isValid = () => {
        const valid = [...prioritizedInitiatives].every((initiative) => {
            return initiative.id in initiativeDates && initiativeDates[initiative.id] >= 0;
        });

        if (!valid) {
            notify.warn("procurement.project.schedule.empty");
        }

        return valid;
    };

    const renderAmountCell = (params) => {
        if (params.value < 0) {
            return <span style={{ color: "red" }}>{params.formattedValue}</span>;
        } else {
            return params.formattedValue;
        }
    };

    useEffect(() => {
        if (projectId) {
            getProject(client, projectId, false, true)
                .then((project) => {
                    setProject(project);
                })
                .catch((error) => {
                    setNullProject();
                    notify.error(error, "procurement.project.projects.fetch");
                });
        } else {
            setNullProject();
        }
    }, [projectId]);

    useUpdateEffect(() => {
        // prevent this effect from triggering when the project loads
        if (!hasChanges) {
            return;
        }

        if (Object.keys(initiativeDates).length && implementationStartDate && isValid()) {
            setPreviewing(true);

            const payload = generatePayloadForSchedule();
            scheduleInitiatives(client, projectId, payload, implementationStartDate, true)
                .then((project) => {
                    const prioritized = sortPrioritizedInitiatives(project);

                    setPrioritizedInitiatives(prioritized);
                    setMonthlyRunRates(project.monthly_run_rates);
                    setNpv(project.npv);
                    setPreviewing(false);
                })
                .catch((error) => {
                    setMonthlyRunRates([]);
                    setNpv(0);
                    setPreviewing(false);

                    notify.error(error, "procurement.project.schedule.dry_run");
                });
        } else {
            setMonthlyRunRates([]);
            setNpv(0);
        }
    }, [implementationStartDate, initiativeDates, hasChanges]);

    if (!projectName || !prioritizedInitiatives || !userData) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.procurement.schedule.loading} />
            </AdvisorContainer>
        );
    }

    const monthsColumns = monthlyRunRates.map((_, index) => {
        return {
            field: `m${index}`,
            headerName: implementationStartDate.add(index, "month").format("MM/YYYY"),
            details: false,
            headerAlign: "center",
            align: "center",
            minWidth: 80,
            maxWidth: 80,
            sortable: false,
            renderCell: (params) => {
                // if current month is larger or eq to initiative start_month and lesser than initiative end_month, render it.
                if (index >= params.row.start_month
                    && index < params.row.start_month + params.row.monthly_run_rates.length) {
                    // render the current month - initiative start_month to target the right month index.
                    const value = params.row.monthly_run_rates[index - params.row.start_month];

                    // we're calculating the max abs value of each initiative for each column.
                    // optimize this if it starts to be a bottleneck.
                    const formattedValue
                        = getCompactFormatter(params.row.monthly_run_rates, config.locale, config.i18n, false, formatCompactAmount)(value);

                    if (value < 0) {
                        return <span style={{ color: "red" }}>{formattedValue}</span>;
                    } else {
                        return formattedValue;
                    }
                } else {
                    return null;
                }
            },
        };
    });

    const columns = [
        {
            field: "name",
            headerName: config.i18n.procurement.identify.name_column,
            details: false,
            minWidth: 512,
            sortable: false,
        },
        {
            field: "business_owner",
            headerName: config.i18n.procurement.owner.business_owner,
            details: true,
            minWidth: 150,
            sortable: false,
            valueGetter: (value, row) => row.id !== "total"
                ? userData[row.business_owner]?.name || row.business_owner
                || config.i18n.procurement.owner.no_owner
                : "",
        },
        {
            field: "business_owner_allocation",
            headerName: config.i18n.procurement.owner.business_owner_allocation,
            details: true,
            minWidth: 100,
            sortable: false,
            valueFormatter: (value, row) => row.id !== "total" ? formatRatioToPercentage(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.business_owner_allocation : "",
        },
        {
            field: "procurement_owner",
            headerName: config.i18n.procurement.owner.procurement_owner,
            details: true,
            minWidth: 150,
            sortable: false,
            valueGetter: (value, row) => row.id !== "total"
                ? userData[row.procurement_owner]?.name || row.procurement_owner
                || config.i18n.procurement.owner.no_owner
                : "",
        },
        {
            field: "procurement_owner_allocation",
            headerName: config.i18n.procurement.owner.procurement_owner_allocation,
            details: true,
            minWidth: 100,
            sortable: false,
            valueFormatter: (value, row) => row.id !== "total" ? formatRatioToPercentage(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.procurement_owner_allocation : "",
        },
        {
            field: "cost_to_achieve",
            headerName: config.i18n.procurement.identify.value_proven.cost_to_achieve,
            details: true,
            minWidth: 126,
            sortable: false,
            headerAlign: "right",
            align: "right",
            valueFormatter: (value, row) => row.id !== "total" ? formatCompactAmount(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.consolidated.cost_to_achieve : "",
            renderCell: renderAmountCell,
        },
        {
            field: "run_rate",
            headerName: config.i18n.procurement.schedule.run_rate,
            details: true,
            minWidth: 100,
            sortable: false,
            headerAlign: "right",
            align: "right",
            valueFormatter: (value, row) => row.id !== "total" ? formatCompactAmount(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.run_rate : "",
            renderCell: renderAmountCell,
        },
        {
            field: "overlap",
            headerName: config.i18n.procurement.schedule.overlap,
            details: true,
            minWidth: 100,
            sortable: false,
            headerAlign: "right",
            align: "right",
            valueFormatter: (value, row) => row.id !== "total" ? formatRatioToPercentage(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.overlap : "",
        },
        {
            field: "amended_run_rate",
            headerName: config.i18n.procurement.schedule.amended_run_rate,
            details: false,
            minWidth: 120,
            sortable: false,
            headerAlign: "right",
            align: "right",
            valueFormatter: (value, row) => row.id !== "total" ? formatCompactAmount(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.amended_run_rate : "",
            renderCell: renderAmountCell,
        },
        {
            field: "amended_npv",
            headerName: config.i18n.procurement.schedule.amended_npv,
            details: true,
            minWidth: 100,
            sortable: false,
            headerAlign: "right",
            align: "right",
            valueFormatter: (value, row) => row.id !== "total" ? formatCompactAmount(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.amended_npv : "",
            renderCell: renderAmountCell,
        },
        {
            field: "first_year_pnl",
            headerName: config.i18n.procurement.schedule.first_year_pnl,
            details: true,
            minWidth: 114,
            sortable: false,
            headerAlign: "right",
            align: "right",
            valueFormatter: (value, row) => row.id !== "total" ? formatCompactAmount(value, config.locale) : "",
            valueGetter: (value, row) => row.id !== "total" ? row.first_year_pnl : "",
            renderCell: renderAmountCell,
        },
        {
            field: "start_month",
            headerName: config.i18n.procurement.schedule.start_month,
            details: false,
            sortable: false,
            minWidth: 120,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return params.row.id !== "total"
                    ? (
                        <EditableTextField
                            value={params.row.id in initiativeDates ? initiativeDates[params.row.id] + 1 : null}
                            formatter={value => formatInteger(value, config.locale)}
                            type="number"
                            min={1}
                            size="small"
                            disableOutline
                            disableAnimation
                            onChange={(value) => {
                                const newInitiativeDates = { ...initiativeDates };
                                newInitiativeDates[params.row.id] = value - 1;

                                setInitiativeDates(newInitiativeDates);
                                setHasChanges(true);
                            }}
                            validator={value => value >= 1}
                            onInvalid={() => {
                                notify.warn("procurement.project.initiative.start_month.negative", params.row.name);
                            }}
                        />
                        )
                    : null;
            },
        },
        ...monthsColumns,
    ];

    const columnsToRender = columns.filter(column => !column.details || showDetails);

    return (
        <AdvisorContainer
            minWidth={0}
            maxWidth="xxl"
            title={`${projectName} – ${config.i18n.procurement.schedule.title}`}
        >
            <Wizard
                steps={steps}
                currentStep="schedule"
                stepValidation={projectStatus}
                hasChanges={hasChanges}
                basePath={basePath}
                originPath={originPath}
                disableStepper
                onSave={() => {
                    if (!isValid()) {
                        return;
                    }

                    setSaving(true);
                    const payload = generatePayloadForSchedule();
                    scheduleInitiatives(client, projectId, payload, implementationStartDate)
                        .then((project) => {
                            setProject(project);

                            setHasChanges(false);
                            setSaving(false);

                            validateProject(client, notify, projectId, setProjectStatus);
                        })
                        .catch((error) => {
                            setSaving(false);
                            notify.error(error, "procurement.project.schedule");
                        });
                }}

                onDiscard={() => setHasChanges(false)}
            >
                <FullscreenLoading
                    loading={saving}
                    label={config.i18n.procurement.schedule.saving}
                />
                <FullscreenLoading
                    loading={previewing}
                    label={config.i18n.procurement.schedule.previewing}
                />
                <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", my: 1 }}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                        <ClickableDatePicker
                            dataCyProp="implementation_date_picker"
                            required
                            views={["year", "month"]}
                            openTo="month"
                            label={config.i18n.procurement.schedule.implementation_start_date}
                            value={implementationStartDate || null}
                            onAccept={(date) => {
                                setImplementationStartDate(date.startOf("month").startOf("date"));
                                setHasChanges(true);
                            }}
                            fullWidth={false}
                        />
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    data-cy="show_details_checkbox"
                                    checked={showDetails}
                                    onChange={event => setShowDetails(event.target.checked)}
                                />
                            )}
                            label={config.i18n.selector.details}
                        />
                    </Stack>
                    <ReadOnlyTextField
                        fullWidth={false}
                        dataCyProp="project_npv"
                        textAlign="left"
                        value={formatCompactAmount(npv, config.locale)}
                        label={config.i18n.procurement.schedule.project_npv}
                    />
                </Stack>
                <ClickableDataGrid
                    dataCy="schedule_table"
                    rows={[
                        ...prioritizedInitiatives,
                        {
                            id: "total",
                            monthly_run_rates: monthlyRunRates,
                            start_month: 0,
                        },
                    ]}
                    columns={columnsToRender}
                    pageSize={100}
                    isRowSelectable={params => params.row.id !== "total"}
                    hideFooterPagination
                    enableScroll
                    responsive
                />
            </Wizard>
        </AdvisorContainer>
    );
};

Schedule.propTypes = {
    steps: PropTypes.array,
    basePath: PropTypes.string,
    originPath: PropTypes.string,
};

export default Schedule;
