import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import logo from "../images/Human-logo-hand.svg";
import { useAuth0 } from "@auth0/auth0-react";

const LandingPage = ({ title, subtitle, includeLogout }) => {
    const { logout } = useAuth0();

    return (
        <Container>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}>
                <img src={logo} alt="HUMAN READY logo" />
                <Typography variant="h3" align="center" gutterBottom sx={{ maxWidth: "60%" }}>
                    {title}
                </Typography>
                { subtitle ? <Typography variant="h5" align="center">{subtitle}</Typography> : null }
                {
                    includeLogout ? (
                        <Button
                            datacy="logout"
                            variant="contained"
                            marginTop="1em"
                            onClick={() => logout({
                                logoutParams: { returnTo: window.location.origin },
                            })}
                        >
                            Logout
                        </Button>
                    ) : null
                }
            </Box>
        </Container>
    );
};

LandingPage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    includeLogout: PropTypes.bool,
};

LandingPage.defaultProps = {
    subtitle: null,
    includeLogout: false,
};

export default LandingPage;
