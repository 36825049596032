import { getCategoricalAxisOptions, getNumericalAxisOptions, getXData } from "./common";
import { getFormattedValue, getFormatter, getTooltipLabel } from "./formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data), data.metadata, config, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 0.4 * baseFontSize },
            formatter: (params) => {
                const formatter = getFormatter(data.label_type.y, config, false);
                const tooltipName = getTooltipLabel(params[0].name, data.metadata, config);

                return `${tooltipName} <br/> ${params[0].marker} ${formatter(params[0].value)}`;
            },
        },
        series: [{
            type: "line",
            data: data.result.map(entry => entry.y[0]),
            symbolSize: 0.15 * baseFontSize,
            lineStyle: { width: 0.07 * baseFontSize },
            label: {
                show: true,
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.data, data.label_type.y, config),
            },
        }],
    };
};
