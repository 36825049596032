import dayjs from "dayjs";
import { defaultLabelFontSize } from "../common";
import { formatFiscalYear, formatQuarter } from "../../../util/formatter";
import { isForecastingEnabled } from "../../../util/feature_flag_util";
import { Dimension } from "../../../client";
import { upperFirstAlphanumericLetter } from "../../../util/string";

const axisLabelFormatterQuarter = (value) => {
    // Formatted to be month/day; display year only in the first label
    const date = dayjs.utc(value);
    if (date.date() === 1 && date.month() % 3 === 0) {
        return formatQuarter(value);
    }
    return "";
};

const axisLabelFormatterMonth = () => {
    // Formatted to be month/day; display year only in the first label
    return {
        year: "{monthStyle|{MMM}'{yy}}", // So that january doesn't go empty
        month: "{monthStyle|{MMM}'{yy}}",
        day: "",
    };
};

const axisLabelFormatterYear = () => {
    return {
        year: "{yearStyle|{yyyy}}",
        month: "",
        day: "",
    };
};

const axisLabelFormatterFiscalYear = (metadata) => {
    return (value) => {
        const date = dayjs.utc(value);
        return formatFiscalYear(date, metadata);
    };
};

export const getTimeSeriesAxisOptions = (name, data, baseFontSize) => {
    let axisLabelFormatter;
    switch (data.parameters.DATE_AGG) {
        case Dimension.DATE_YEAR:
            axisLabelFormatter = axisLabelFormatterYear();
            break;
        case Dimension.DATE_YEAR_QUARTER:
            axisLabelFormatter = axisLabelFormatterQuarter;
            break;
        case Dimension.DATE_YEAR_MONTH:
            axisLabelFormatter = axisLabelFormatterMonth();
            break;
        case Dimension.FISCAL_YEAR:
            axisLabelFormatter = axisLabelFormatterFiscalYear(data.metadata);
            break;
        default:
            throw new Error(`No formatter for DATE_AGG '${data.parameters.DATE_AGG}'.`);
    }

    return {
        name: upperFirstAlphanumericLetter(name),
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "time",
        min: "dataMin",
        max: "dataMax",
        axisLabel: {
            formatter: axisLabelFormatter,
            rich: {
                yearStyle: {
                    fontSize: defaultLabelFontSize(baseFontSize),
                },
                monthStyle: {
                    fontSize: defaultLabelFontSize(baseFontSize),
                },
            },
            showMinLabel: true,
            showMaxLabel: true,
        },
    };
};

export function getForecastingLine(data, config, baseFontSize) {
    const isForecasting = isForecastingEnabled(config) && data.parameters?.SCENARIO != null;

    if (!isForecasting) {
        return {};
    }

    const cutoffDate = dayjs.utc(data.parameters?.CUTOFF_DATE || config.time.forecasting?.min);

    // Forecasting line is placed between the last historical value and the first forecasted value
    const firstForecastEntry = data.result.find(value => cutoffDate.isSame(value.x, "month") || cutoffDate.isBefore(value.x, "month"));

    if (!firstForecastEntry) {
        return {};
    }

    let cutoffLineDate = firstForecastEntry.x;

    // The middle between two date points in the chart depends on date aggregation
    switch (data.parameters.DATE_AGG) {
        case "DATE_YEAR":
            cutoffLineDate = cutoffLineDate.subtract(6, "month");
            break;
        case "FISCAL_YEAR":
            return {}; // FIXME #6951: Find a solution to display forecasting line in fiscal years
        case "DATE_YEAR_QUARTER":
            cutoffLineDate = cutoffLineDate.subtract(45, "day");
            break;
        case "DATE_YEAR_MONTH":
            cutoffLineDate = cutoffLineDate.subtract(15, "day");
            break;
    }

    return {
        markLine: {
            symbol: "none",
            animation: false,
            data: [{
                xAxis: cutoffLineDate.toDate(),
                lineStyle: { width: 2, color: "grey" },
                label: {
                    formatter: config.i18n.chart.lines.actual,
                    fontSize: defaultLabelFontSize(baseFontSize),
                    position: "insideEndTop",
                },
            },
            {
                xAxis: cutoffLineDate.toDate(),
                lineStyle: { width: 2, color: "grey" },
                label: {
                    formatter: config.i18n.chart.lines.forecast,
                    fontSize: defaultLabelFontSize(baseFontSize),
                    position: "insideEndBottom",
                },
            }],
        },
    };
}

export function getFormatterFromDateAgg(dateAgg) {
    switch (dateAgg) {
        case "DATE_YEAR":
            return "year";
        case "DATE_YEAR_MONTH":
            return "year-month";
        case "DATE_YEAR_QUARTER":
            return "year-quarter";
        case "FISCAL_YEAR":
            return "fiscal-year";
        default:
            throw new Error("DATE_AGG not supported for formatting.");
    }
}
