import React from "react";
import { keyCodes } from "@silevis/reactgrid";
import DeleteIconButton from "../../../components/button/DeleteIconButton";

const blankFunction = () => { };

export const DeleteButtonCellTemplate = {
    getCompatibleCell(uncertainCell) {
        return {
            ...uncertainCell,
            text: uncertainCell.text || "",
            value: 0,
            onClick: uncertainCell.onClick || blankFunction,
        };
    },
    handleKeyDown(cell, keyCode) {
        if (keyCode === keyCodes.ENTER) {
            cell.onClick();
        }
        return { cell, enableEditMode: false };
    },
    render(cell) {
        return (
            <DeleteIconButton
                onClick={() => cell.onClick(cell.hammer_id)}
            >
            </DeleteIconButton>
        );
    },
};

export const getDeleteButtonCell = (id, onClick) => ({
    type: "delete_button",
    hammer_id: id,
    onClick,
    style: { justifyContent: "center" },
});
