import React from "react";
import { keyCodes } from "@silevis/reactgrid";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const blankFunction = () => { };

export const AddButtonCellTemplate = {
    getCompatibleCell(uncertainCell) {
        return {
            ...uncertainCell,
            text: uncertainCell.text || "",
            value: 0,
            onClick: uncertainCell.onClick || blankFunction,
        };
    },
    handleKeyDown(cell, keyCode) {
        if (keyCode === keyCodes.ENTER) {
            cell.onClick();
        }
        return { cell, enableEditMode: false };
    },
    render(cell) {
        return (
            <IconButton
                color="primary"
                onPointerDown={e => e.stopPropagation()}
                onClick={cell.onClick}
            >
                <AddIcon />
            </IconButton>
        );
    },
};

export const getAddButtonCell = onClick => ({
    type: "add_button",
    onClick,
    style: { justifyContent: "center" },
});
