import React, { useContext } from "react";
import { Box } from "@mui/material";

import AdvisorContainer from "../layout/AdvisorContainer";
import TryThis from "./TryThis";
import { SearchBar } from "../components/SearchBar";
import { AppContext } from "../AppRouter";

const AskTry = () => {
    const { config } = useContext(AppContext);

    return (
        <AdvisorContainer title={config.i18n.page.ask_me} showTitle={false}>
            <Box sx={{ flex: 2, display: "flex", flexDirection: "column-reverse" }}>
                <SearchBar />
            </Box>
            <Box sx={{ flex: 5, display: "flex", flexDirection: "column", mt: 6 }}>
                <TryThis />
            </Box>
        </AdvisorContainer>
    );
};

export default AskTry;
