import dayjs from "dayjs";
import DimensionBox from "../DimensionBox";
import React, { useContext } from "react";
import { Checkbox, FormControlLabel, Grid2 as Grid } from "@mui/material";
import PropTypes from "prop-types";
import ClickableDatePicker from "../ClickableDatePicker";
import { AppContext } from "../../AppRouter";

const CurrencySelector = ({ disabled, forexDate, onUpdate }) => {
    const { config } = useContext(AppContext);

    const historicalMin = config.time.historical.min;
    const historicalMax = config.time.historical.max;

    // since dates are a URL parameter, inside a JSON object, it will not be deserialized as dayjs object.
    // so, we need to enforce dayjs here.
    const date = forexDate ? dayjs.utc(forexDate) : historicalMax;

    return (
        <DimensionBox
            title={config.i18n.customization_bar.currency}
            dataCyProp="currency-selector"
            collapsible
            isClosed={!forexDate}
        >
            <Grid container spacing={1}>
                <Grid size={6}>
                    <ClickableDatePicker
                        dataCyProp="forexDate_selector"
                        label={config.i18n.Dimension.FOREX_DATE}
                        disabled={disabled || !forexDate}
                        disableFuture
                        minDate={historicalMin}
                        maxDate={historicalMax}
                        value={date}
                        onChange={val => onUpdate(val)}
                        onAccept={val => onUpdate(val.startOf("month").startOf("date"))}
                    />
                </Grid>
                <Grid size={6}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                data-cy="constantForex_checkbox"
                                disabled={disabled}
                                checked={!!forexDate}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        onUpdate(date);
                                    } else {
                                        onUpdate(null);
                                    }
                                }}
                            />
                        )}
                        label={config.i18n.selector.constant_forex}
                    />
                </Grid>
            </Grid>
        </DimensionBox>
    );
};

CurrencySelector.propTypes = {
    disabled: PropTypes.bool,
    forexDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onUpdate: PropTypes.func,
};

export default CurrencySelector;
