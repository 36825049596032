"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoggingService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var LoggingService = /** @class */ (function () {
    function LoggingService() {
    }
    /**
     * Get All Logs Levels
     * @returns string Successful Response
     * @throws ApiError
     */
    LoggingService.loggingGetAllLogsLevels = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/loglevel',
        });
    };
    /**
     * Change Advisor Log Level
     * @param level
     * @returns string Successful Response
     * @throws ApiError
     */
    LoggingService.loggingChangeAdvisorLogLevel = function (level) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/loglevel/advisor/{level}',
            path: {
                'level': level,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Change Uvicorn Log Level
     * @param level
     * @returns string Successful Response
     * @throws ApiError
     */
    LoggingService.loggingChangeUvicornLogLevel = function (level) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/loglevel/uvicorn/{level}',
            path: {
                'level': level,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Change Database Log Level
     * @param level
     * @returns string Successful Response
     * @throws ApiError
     */
    LoggingService.loggingChangeDatabaseLogLevel = function (level) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/loglevel/sqlalchemy/{level}',
            path: {
                'level': level,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Reset Logs Levels
     * @returns string Successful Response
     * @throws ApiError
     */
    LoggingService.loggingResetLogsLevels = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/loglevel/reset',
        });
    };
    return LoggingService;
}());
exports.LoggingService = LoggingService;
