import { roundToDecimalCase } from "../../util/util";
import { getAxisLabel, getFormatter, getTooltipLabel } from "./formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        tooltip: {
            confine: true,
            textStyle: { fontSize: 0.4 * baseFontSize },
            trigger: "item",
            formatter: (params) => {
                const formatter = getFormatter(data.label_type.y, config, false);
                const tooltipName = getTooltipLabel(params.data.id, data.metadata, config);

                return `${tooltipName} <br/> ${params.marker} ${formatter(params.value)}`;
            },
        },
        series: [{
            type: "pie",
            data: data.result.map(entry => ({ id: entry.label, name: getAxisLabel(entry.x, data.metadata, config), value: entry.y[0] })),
            top: 10,
            label: {
                fontSize: 0.3 * baseFontSize,
                lineHeight: 0.5 * baseFontSize,
                formatter: function (params) {
                    const percentage = roundToDecimalCase(params.percent);
                    return [params.name, "\n", percentage, "%"].join("");
                },
            },
        }],
    };
};
