import React, { useContext, useState } from "react";
import { useLocation } from "react-router";
import { Box, Collapse, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HelpIcon from "@mui/icons-material/Help";
import Widgets from "@mui/icons-material/Widgets";
import InsightsIcon from "@mui/icons-material/Insights";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import PropTypes from "prop-types";
import HardwareIcon from "@mui/icons-material/Hardware";

import { Link } from "react-router-dom";
import { Requires } from "../util/Requires";
import { AppContext } from "../AppRouter";

const drawerWidth = 200;

const Menu = ({ isDrawerPinned }) => {
    const { config, user } = useContext(AppContext);
    const location = useLocation();
    const [openOptimize, setOpenOptimize] = useState(true);
    const [openUserGuide, setOpenUserGuide] = useState(false);

    return (
        <Drawer
            datacy="menu"
            variant={isDrawerPinned ? "persistent" : "temporary"}
            elevation={1}
            hideBackdrop
            sx={{
                "width": isDrawerPinned ? drawerWidth : 0,
                "flexShrink": 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },
            }}
            open={isDrawerPinned}
            transitionDuration={300}
        >
            <Toolbar />
            <Box sx={{ overflow: "auto" }} datacy="side_menu">
                <List>
                    <Requires flag="ASK_ME">
                        <ListItemButton
                            data-cy="side_menu-ask"
                            key="menu-ask"
                            component={Link}
                            to="/ask"
                            selected={location.pathname === "/ask"}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <QuestionAnswerIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.ask_me} />
                        </ListItemButton>
                    </Requires>
                    <Requires flag="EXPLORE">
                        <ListItemButton
                            key="menu-explore"
                            component={Link}
                            to="/explore"
                            selected={location.pathname === "/explore"}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <Widgets />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.explore} />
                        </ListItemButton>
                    </Requires>
                    <Requires flag="FORECAST">
                        <ListItemButton
                            data-cy="side_menu-forecast"
                            disabled={!user.permissions?.FORECAST?.CREATE && !user.permissions?.FORECAST?.READ}
                            key="menu-scenario"
                            component={Link}
                            to="/scenario"
                            selected={location.pathname === "/scenario"}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <InsightsIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.scenario} />
                        </ListItemButton>
                    </Requires>
                    <Requires flag="REPORT">
                        <ListItemButton
                            data-cy="side_menu-report"
                            disabled={!user.permissions?.REPORT?.CREATE && !user.permissions?.REPORT?.READ}
                            key="menu-report"
                            component={Link}
                            to="/report"
                            selected={location.pathname === "/report"}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.report} />
                        </ListItemButton>
                    </Requires>
                    <Requires flag="COLLECTOR">
                        <ListItemButton
                            data-cy="side_menu-collector"
                            disabled={!user.permissions?.COLLECTOR?.CREATE && !user.permissions?.COLLECTOR?.READ}
                            key="menu-collector"
                            component={Link}
                            to="/collector"
                            selected={location.pathname === "/collector"}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <LibraryAddIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.collector} />
                        </ListItemButton>
                    </Requires>
                    <Requires flag="OPTIMIZE">
                        <ListItemButton
                            disabled={!user.permissions?.OPTIMIZE?.CREATE && !user.permissions?.OPTIMIZE?.READ}
                            data-cy="side_menu-optimize"
                            key="menu-optimize"
                            onClick={() => setOpenOptimize(!openOptimize)}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <TuneIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.optimize} />
                            {openOptimize ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        <Collapse in={openOptimize} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    data-cy="side_menu-procurement"
                                    disabled={!user.permissions?.OPTIMIZE?.CREATE && !user.permissions?.OPTIMIZE?.READ}
                                    component={Link}
                                    to="/procurement"
                                    selected={location.pathname === "/procurement"}
                                    sx={{ pl: 4 }}
                                >
                                    <ListItemIcon sx={{ minWidth: "38px" }}>
                                        <ShoppingCartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={config.i18n.page.procurement} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </Requires>
                    <Requires flag="HAMMER">
                        <ListItemButton
                            data-cy="side_menu-hammer"
                            disabled={!user.permissions?.HAMMER?.CREATE && !user.permissions?.REPORT?.READ}
                            key="menu-hammer"
                            component={Link}
                            to="/hammer"
                            selected={location.pathname === "/hammer"}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <HardwareIcon sx={{ scale: 200 }} />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.hammer} />
                        </ListItemButton>
                    </Requires>
                    <Requires flag="USER_GUIDE">
                        <ListItemButton
                            data-cy="side_menu-user_guide"
                            key="menu-user-guide"
                            onClick={() => setOpenUserGuide(!openUserGuide)}
                        >
                            <ListItemIcon sx={{ minWidth: "38px" }}>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary={config.i18n.page.user_guide} />
                            {openUserGuide ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        <Collapse in={openUserGuide} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Requires flag="ASK_ME">
                                    <ListItemButton
                                        data-cy="side_menu-user-guide-ask"
                                        component={Link}
                                        to="/user-guide/ask"
                                        selected={location.pathname === "/user-guide/ask"}
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary={config.i18n.page.ask_me} />
                                    </ListItemButton>
                                </Requires>
                                <Requires flag="REPORT">
                                    <ListItemButton
                                        data-cy="side_menu-user-guide-report"
                                        component={Link}
                                        to="/user-guide/report"
                                        selected={location.pathname === "/user-guide/report"}
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary={config.i18n.page.report} />
                                    </ListItemButton>
                                </Requires>
                                <Requires flag="FORECAST">
                                    <ListItemButton
                                        data-cy="side_menu-user-guide-ask"
                                        component={Link}
                                        to="/user-guide/forecast"
                                        selected={location.pathname === "/user-guide/forecast"}
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary={config.i18n.page.scenario} />
                                    </ListItemButton>
                                </Requires>
                                <Requires flag="OPTIMIZE">
                                    <ListItemButton
                                        data-cy="side_menu-user-guide-ask"
                                        component={Link}
                                        to="/user-guide/optimize"
                                        selected={location.pathname === "/user-guide/optimize"}
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary={config.i18n.page.optimize} />
                                    </ListItemButton>
                                </Requires>
                            </List>
                        </Collapse>
                    </Requires>
                </List>
            </Box>
        </Drawer>
    );
};

Menu.propTypes = {
    isDrawerPinned: PropTypes.bool,
};

export default Menu;
