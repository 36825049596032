import { getNumericalAxisOptions } from "../common";
import { getForecastingLine, getFormatterFromDateAgg, getTimeSeriesAxisOptions } from "./common";
import { formatValue } from "../../../util/formatter";
import { getFormattedValue, getFormatter } from "../formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    let chartOptions = {
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 0.4 * baseFontSize },
            formatter: ([series]) => {
                const [date, value] = series.data;

                const dateFormatter = getFormatterFromDateAgg(data.parameters["DATE_AGG"]);
                const valueFormatter = getFormatter(data.label_type.y, config, false);

                const formattedDate = formatValue(date, dateFormatter, config.locale, config.i18n, data.metadata);
                const formattedValue = valueFormatter(value);

                return `${formattedDate} <br/> ${series.marker} ${formattedValue}`;
            },
        },
        series: [{
            type: "bar",
            data: data.result.map(entry => [entry.x.toDate(), entry.y[0]]),
            labelLayout: { hideOverlap: true },
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.data[1], data.label_type.y, config),
            },
            ...getForecastingLine(data, config, baseFontSize), // time results may have forecasting line
        }],
    };

    // Min & Max labels are not formatted to the appropriate time format, so they are not shown
    chartOptions.xAxis.axisLabel.showMaxLabel = false;
    chartOptions.xAxis.axisLabel.showMinLabel = false;

    return chartOptions;
};
