"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentType = void 0;
var DocumentType;
(function (DocumentType) {
    DocumentType["INVOICE"] = "INVOICE";
    DocumentType["BUDGET"] = "BUDGET";
    DocumentType["ORDER"] = "ORDER";
    DocumentType["EXTERNAL"] = "EXTERNAL";
})(DocumentType || (exports.DocumentType = DocumentType = {}));
