import { getNumericalAxisOptions } from "./common";
import { getFormatter, getTooltipLabel } from "./formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        xAxis: getNumericalAxisOptions(data.labels.x, data.result.map(el => el.x), config, data.label_type.x, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            confine: true,
            textStyle: { fontSize: 0.4 * baseFontSize },
            trigger: "item",
            formatter: (params) => {
                const xFormatter = getFormatter(data.label_type.x, config, false);
                const yFormatter = getFormatter(data.label_type.y, config, false);

                const [x, y] = params.value;
                const tooltipText = params.data.label ? `<b>${getTooltipLabel(params.data.id, data.metadata, config)}</b></br>` : "";

                return tooltipText
                    + `${data.labels.x}: ${xFormatter(x)}</br>`
                    + `${data.labels.y}: ${yFormatter(y)}`;
            },
        },
        series: [{
            type: "scatter",
            data: data.result.map(entry => ({
                id: entry.label,
                value: [entry.x, entry.y[0]],
            })),
        }],
    };
};
