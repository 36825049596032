import { getCategoricalAxisOptions, getNumericalAxisOptions } from "./common";
import { formatTemplate } from "../../util/formatter";
import { getFormattedValue, getFormatter, getFormatterFromLabelType } from "./formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    function formatBin(value, index) {
        const formatterType = getFormatterFromLabelType(data.label_type["x"], true) || "";
        if (value[0] === null) {
            const template = `< {{ ${formatterType} var1 }}`;
            return formatTemplate(template, { var1: value[1] }, config.locale, config.i18n);
        } else if (value[1] === null) {
            const template = `≥ {{ ${formatterType} var1 }}`;
            return formatTemplate(template, { var1: value[0] }, config.locale, config.i18n);
        } else {
            const template = `[{{ ${formatterType} var1 }} ; {{ ${formatterType} var2 }}`;
            // making sure the last bin shows as a closed interval.
            const endingChar = index === data.result.length - 1 ? `]` : `[`;

            return formatTemplate(template + endingChar, { var1: value[0], var2: value[1] }, config.locale, config.i18n);
        }
    }

    // specify how to show a 2 position array.
    const xAxisOptions = getCategoricalAxisOptions(data.labels.x, data.result.map((el, index) => formatBin(el.label, index)), data.metadata, config, baseFontSize);
    return {
        xAxis: {
            ...xAxisOptions,
            axisLabel: { // Override dimensions & margins to better suit an histogram chart
                ...xAxisOptions.axisLabel,
                height: 2 * 0.40 * baseFontSize,
                margin: 0.20 * baseFontSize,
                lineHeight: 0.40 * baseFontSize,
                overflow: "break",
            },
        },
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            confine: true,
            textStyle: { fontSize: 0.4 * baseFontSize },
            axisPointer: { type: "shadow" },
            formatter: (params) => {
                const formatter = getFormatter(data.label_type.y, config, false);
                return `${params[0].name} <br/> ${params[0].marker} ${formatter(params[0].value)}`;
            },
        },
        series: [{
            type: "bar",
            data: data.result.map(entry => entry.y[0]),
            barWidth: "99%",
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: value => getFormattedValue(value.data, data.label_type.y, config),
            },
        }],
    };
};
