"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountingTreatment = void 0;
var AccountingTreatment;
(function (AccountingTreatment) {
    AccountingTreatment["COST"] = "COST";
    AccountingTreatment["EXPENSES"] = "EXPENSES";
    AccountingTreatment["GROSS_PROFIT"] = "GROSS_PROFIT";
    AccountingTreatment["EBITDA"] = "EBITDA";
    AccountingTreatment["ADJUSTED_EBITDA"] = "ADJUSTED_EBITDA";
    AccountingTreatment["EBIT"] = "EBIT";
    AccountingTreatment["EBT"] = "EBT";
    AccountingTreatment["NET_INCOME"] = "NET_INCOME";
    AccountingTreatment["NET_REVENUE"] = "NET_REVENUE";
    AccountingTreatment["NET_SALES"] = "NET_SALES";
    AccountingTreatment["GROSS_SALES"] = "GROSS_SALES";
    AccountingTreatment["DISCOUNTS"] = "DISCOUNTS";
    AccountingTreatment["REFUNDS"] = "REFUNDS";
    AccountingTreatment["OTHER_SALES"] = "OTHER_SALES";
    AccountingTreatment["INTERNAL_SALES"] = "INTERNAL_SALES";
    AccountingTreatment["OTHER_REVENUE"] = "OTHER_REVENUE";
    AccountingTreatment["FIXED_REVENUE"] = "FIXED_REVENUE";
    AccountingTreatment["STOCK_INCREASE"] = "STOCK_INCREASE";
    AccountingTreatment["OTHER_GAINS"] = "OTHER_GAINS";
    AccountingTreatment["COGS"] = "COGS";
    AccountingTreatment["OPEX"] = "OPEX";
    AccountingTreatment["CAPEX"] = "CAPEX";
    AccountingTreatment["COST_OF_SALES"] = "COST_OF_SALES";
    AccountingTreatment["VARIABLE_COSTS"] = "VARIABLE_COSTS";
    AccountingTreatment["OTHER_COGS"] = "OTHER_COGS";
    AccountingTreatment["PRODUCTION_VARIABLE_COSTS"] = "PRODUCTION_VARIABLE_COSTS";
    AccountingTreatment["MAINTENANCE_VARIABLE_COSTS"] = "MAINTENANCE_VARIABLE_COSTS";
    AccountingTreatment["COMMERCIAL_VARIABLE_COSTS"] = "COMMERCIAL_VARIABLE_COSTS";
    AccountingTreatment["OTHER_VARIABLE_COSTS"] = "OTHER_VARIABLE_COSTS";
    AccountingTreatment["OTHER_LOSSES"] = "OTHER_LOSSES";
    AccountingTreatment["GROSS_PROFIT_ADJUSTMENTS"] = "GROSS_PROFIT_ADJUSTMENTS";
    AccountingTreatment["EBITDA_ADJUSTMENTS"] = "EBITDA_ADJUSTMENTS";
    AccountingTreatment["PERSONNEL_COSTS"] = "PERSONNEL_COSTS";
    AccountingTreatment["MAINTENANCE_COSTS"] = "MAINTENANCE_COSTS";
    AccountingTreatment["GA_COSTS"] = "GA_COSTS";
    AccountingTreatment["OTHER_OPEX"] = "OTHER_OPEX";
    AccountingTreatment["DIRECT_OPEX"] = "DIRECT_OPEX";
    AccountingTreatment["INDIRECT_OPEX"] = "INDIRECT_OPEX";
    AccountingTreatment["DIRECT_PERSONNEL_COSTS"] = "DIRECT_PERSONNEL_COSTS";
    AccountingTreatment["DIRECT_MAINTENANCE_COSTS"] = "DIRECT_MAINTENANCE_COSTS";
    AccountingTreatment["DIRECT_GA_COSTS"] = "DIRECT_GA_COSTS";
    AccountingTreatment["DIRECT_OTHER_OPEX"] = "DIRECT_OTHER_OPEX";
    AccountingTreatment["INDIRECT_PERSONNEL_COSTS"] = "INDIRECT_PERSONNEL_COSTS";
    AccountingTreatment["INDIRECT_MAINTENANCE_COSTS"] = "INDIRECT_MAINTENANCE_COSTS";
    AccountingTreatment["INDIRECT_GA_COSTS"] = "INDIRECT_GA_COSTS";
    AccountingTreatment["INDIRECT_OTHER_OPEX"] = "INDIRECT_OTHER_OPEX";
    AccountingTreatment["STOCK_DECREASE"] = "STOCK_DECREASE";
    AccountingTreatment["PROVISIONS"] = "PROVISIONS";
    AccountingTreatment["BAD_DEBT"] = "BAD_DEBT";
    AccountingTreatment["ADJUSTMENTS"] = "ADJUSTMENTS";
    AccountingTreatment["OTHER_COSTS"] = "OTHER_COSTS";
    AccountingTreatment["OTHER_OTHER_COSTS"] = "OTHER_OTHER_COSTS";
    AccountingTreatment["AMORTIZATION"] = "AMORTIZATION";
    AccountingTreatment["DEPRECIATION"] = "DEPRECIATION";
    AccountingTreatment["TAX"] = "TAX";
    AccountingTreatment["FINANCIAL_RESULTS"] = "FINANCIAL_RESULTS";
    AccountingTreatment["PRODUCTION"] = "PRODUCTION";
    AccountingTreatment["CONSUMPTION"] = "CONSUMPTION";
    AccountingTreatment["WRITEOFF"] = "WRITEOFF";
    AccountingTreatment["EMPLOYEE"] = "EMPLOYEE";
    AccountingTreatment["CLIENT"] = "CLIENT";
    AccountingTreatment["STORE_AREA"] = "STORE_AREA";
    AccountingTreatment["OTHER"] = "OTHER";
    AccountingTreatment["COMMISSIONS_COSTS"] = "COMMISSIONS_COSTS";
    AccountingTreatment["LOGISTICS_COSTS"] = "LOGISTICS_COSTS";
    AccountingTreatment["STOCK_VARIATION"] = "STOCK_VARIATION";
    AccountingTreatment["STOCK_VARIATION_FIXED"] = "STOCK_VARIATION_FIXED";
    AccountingTreatment["STOCK_VARIATION_VARIABLE"] = "STOCK_VARIATION_VARIABLE";
    AccountingTreatment["STOCK_INCREASE_FIXED"] = "STOCK_INCREASE_FIXED";
    AccountingTreatment["STOCK_INCREASE_VARIABLE"] = "STOCK_INCREASE_VARIABLE";
    AccountingTreatment["STOCK_DECREASE_FIXED"] = "STOCK_DECREASE_FIXED";
    AccountingTreatment["STOCK_DECREASE_VARIABLE"] = "STOCK_DECREASE_VARIABLE";
    AccountingTreatment["STOCK_WRITEOFF"] = "STOCK_WRITEOFF";
    AccountingTreatment["STOCK_WRITEOFF_FIXED"] = "STOCK_WRITEOFF_FIXED";
    AccountingTreatment["STOCK_WRITEOFF_VARIABLE"] = "STOCK_WRITEOFF_VARIABLE";
    AccountingTreatment["BONUS"] = "BONUS";
    AccountingTreatment["DEPRECIATION_AMORTIZATION"] = "DEPRECIATION_AMORTIZATION";
    AccountingTreatment["ASSETS"] = "ASSETS";
    AccountingTreatment["NON_CURRENT_ASSETS"] = "NON_CURRENT_ASSETS";
    AccountingTreatment["TANGIBLE_ASSETS"] = "TANGIBLE_ASSETS";
    AccountingTreatment["GROSS_TANGIBLE_ASSETS"] = "GROSS_TANGIBLE_ASSETS";
    AccountingTreatment["TANGIBLE_DEPRECIATION"] = "TANGIBLE_DEPRECIATION";
    AccountingTreatment["TANGIBLE_ASSET_IMPAIRMENTS"] = "TANGIBLE_ASSET_IMPAIRMENTS";
    AccountingTreatment["OTHER_TANGIBLE_ASSETS"] = "OTHER_TANGIBLE_ASSETS";
    AccountingTreatment["INVESTMENTS_IN_PROGRESS"] = "INVESTMENTS_IN_PROGRESS";
    AccountingTreatment["INTANGIBLE_ASSETS"] = "INTANGIBLE_ASSETS";
    AccountingTreatment["GROSS_INTANGIBLE_ASSETS"] = "GROSS_INTANGIBLE_ASSETS";
    AccountingTreatment["INTANGIBLE_DEPRECIATION"] = "INTANGIBLE_DEPRECIATION";
    AccountingTreatment["OTHER_INTANGIBLE_ASSETS"] = "OTHER_INTANGIBLE_ASSETS";
    AccountingTreatment["GOODWILL"] = "GOODWILL";
    AccountingTreatment["FINANCIAL_ASSETS"] = "FINANCIAL_ASSETS";
    AccountingTreatment["DEFERRED_TAX_ASSETS"] = "DEFERRED_TAX_ASSETS";
    AccountingTreatment["NON_CURRENT_LOANS_TO_GROUP_COMPANIES"] = "NON_CURRENT_LOANS_TO_GROUP_COMPANIES";
    AccountingTreatment["OTHER_NON_CURRENT_ASSETS"] = "OTHER_NON_CURRENT_ASSETS";
    AccountingTreatment["CURRENT_ASSETS"] = "CURRENT_ASSETS";
    AccountingTreatment["CASH"] = "CASH";
    AccountingTreatment["INVENTORIES"] = "INVENTORIES";
    AccountingTreatment["ACCOUNTS_RECEIVABLES"] = "ACCOUNTS_RECEIVABLES";
    AccountingTreatment["TRADE_RECEIVABLES"] = "TRADE_RECEIVABLES";
    AccountingTreatment["CURRENT_IMPAIRMENTS"] = "CURRENT_IMPAIRMENTS";
    AccountingTreatment["OTHER_ACCOUNTS_RECEIVABLES"] = "OTHER_ACCOUNTS_RECEIVABLES";
    AccountingTreatment["OTHER_RECEIVABLES"] = "OTHER_RECEIVABLES";
    AccountingTreatment["ACCRUED_REVENUES"] = "ACCRUED_REVENUES";
    AccountingTreatment["DEFERRED_COSTS"] = "DEFERRED_COSTS";
    AccountingTreatment["OTHER_DEBTORS"] = "OTHER_DEBTORS";
    AccountingTreatment["OTHER_OTHER_RECEIVABLES"] = "OTHER_OTHER_RECEIVABLES";
    AccountingTreatment["CURRENT_LOANS_TO_GROUP_COMPANIES"] = "CURRENT_LOANS_TO_GROUP_COMPANIES";
    AccountingTreatment["OTHER_CURRENT_ASSETS"] = "OTHER_CURRENT_ASSETS";
    AccountingTreatment["EQUITY"] = "EQUITY";
    AccountingTreatment["SHARE_CAPITAL"] = "SHARE_CAPITAL";
    AccountingTreatment["SHAREHOLDERS"] = "SHAREHOLDERS";
    AccountingTreatment["LEGAL_RESERVES"] = "LEGAL_RESERVES";
    AccountingTreatment["OTHER_RESERVES"] = "OTHER_RESERVES";
    AccountingTreatment["RETAINED_INCOME"] = "RETAINED_INCOME";
    AccountingTreatment["FINANCIAL_ASSETS_ADJUSTMENTS"] = "FINANCIAL_ASSETS_ADJUSTMENTS";
    AccountingTreatment["REVALUATIONS"] = "REVALUATIONS";
    AccountingTreatment["OTHER_VARIATIONS"] = "OTHER_VARIATIONS";
    AccountingTreatment["OTHER_EQUITY"] = "OTHER_EQUITY";
    AccountingTreatment["LIABILITIES"] = "LIABILITIES";
    AccountingTreatment["NON_CURRENT_LIABILITIES"] = "NON_CURRENT_LIABILITIES";
    AccountingTreatment["LONG_TERM_LOANS_OBTAINED"] = "LONG_TERM_LOANS_OBTAINED";
    AccountingTreatment["LONG_TERM_BANK_LOANS"] = "LONG_TERM_BANK_LOANS";
    AccountingTreatment["NON_CURRENT_LOANS_FROM_GROUP_COMPANIES"] = "NON_CURRENT_LOANS_FROM_GROUP_COMPANIES";
    AccountingTreatment["DEFERRED_TAX_LIABILITIES"] = "DEFERRED_TAX_LIABILITIES";
    AccountingTreatment["OTHER_NON_CURRENT_LIABILITIES"] = "OTHER_NON_CURRENT_LIABILITIES";
    AccountingTreatment["CURRENT_LIABILITIES"] = "CURRENT_LIABILITIES";
    AccountingTreatment["SHORT_TERM_LOANS_OBTAINED"] = "SHORT_TERM_LOANS_OBTAINED";
    AccountingTreatment["SHORT_TERM_BANK_LOANS"] = "SHORT_TERM_BANK_LOANS";
    AccountingTreatment["CURRENT_LOANS_FROM_GROUP_COMPANIES"] = "CURRENT_LOANS_FROM_GROUP_COMPANIES";
    AccountingTreatment["ACCOUNTS_PAYABLE"] = "ACCOUNTS_PAYABLE";
    AccountingTreatment["SUPPLIERS"] = "SUPPLIERS";
    AccountingTreatment["AP_TO_OPERATIONAL_ASSET_SUPPLIERS"] = "AP_TO_OPERATIONAL_ASSET_SUPPLIERS";
    AccountingTreatment["AP_TO_OTHER_ASSET_SUPPLIERS"] = "AP_TO_OTHER_ASSET_SUPPLIERS";
    AccountingTreatment["ACCOUNTS_PAYABLE_TO_GROUP_COMPANIES"] = "ACCOUNTS_PAYABLE_TO_GROUP_COMPANIES";
    AccountingTreatment["INCOME_TAX_LIABILITIES"] = "INCOME_TAX_LIABILITIES";
    AccountingTreatment["OTHER_ACCOUNTS_PAYABLE"] = "OTHER_ACCOUNTS_PAYABLE";
    AccountingTreatment["ACCRUED_COSTS"] = "ACCRUED_COSTS";
    AccountingTreatment["DEFERRED_REVENUES"] = "DEFERRED_REVENUES";
    AccountingTreatment["OTHER_CREDITORS"] = "OTHER_CREDITORS";
    AccountingTreatment["OTHER_AP_TO_OPERATIONAL_ASSET_SUPPLIERS"] = "OTHER_AP_TO_OPERATIONAL_ASSET_SUPPLIERS";
    AccountingTreatment["OTHER_AP_TO_STATE_PUBLIC_ENTITIES"] = "OTHER_AP_TO_STATE_PUBLIC_ENTITIES";
    AccountingTreatment["OTHER_OTHER_ACCOUNTS_PAYABLE"] = "OTHER_OTHER_ACCOUNTS_PAYABLE";
    AccountingTreatment["OTHER_CURRENT_LIABILITIES"] = "OTHER_CURRENT_LIABILITIES";
    AccountingTreatment["OTHER_IMPAIRMENTS"] = "OTHER_IMPAIRMENTS";
    AccountingTreatment["OTHER_LOANS"] = "OTHER_LOANS";
    AccountingTreatment["REVENUE"] = "REVENUE";
})(AccountingTreatment || (exports.AccountingTreatment = AccountingTreatment = {}));
