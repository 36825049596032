"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HammerMeasure = void 0;
var HammerMeasure;
(function (HammerMeasure) {
    HammerMeasure["QUANTITY_KEEP_AMOUNT"] = "QUANTITY_KEEP_AMOUNT";
    HammerMeasure["AMOUNT_KEEP_QUANTITY"] = "AMOUNT_KEEP_QUANTITY";
})(HammerMeasure || (exports.HammerMeasure = HammerMeasure = {}));
