import { getForecastingLine, getFormatterFromDateAgg, getTimeSeriesAxisOptions } from "./common";
import { getLegend, getNumericalAxisOptions } from "../common";
import { formatValue } from "../../../util/formatter";
import { getAxisLabel, getFormattedValue, getFormatter } from "../formatter";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    return {
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 0.4 * baseFontSize },
            formatter: (params) => {
                const dateFormatter = getFormatterFromDateAgg(data.parameters["DATE_AGG"]);
                const valueFormatter = getFormatter(data.label_type.y, config, false);

                const date = params[0].data[0];

                let tooltipText = formatValue(date, dateFormatter, config.locale, config.i18n, data.metadata);

                params.forEach((series) => {
                    const value = series.data[1];
                    tooltipText += value !== Number.EPSILON ? `<br/>${series.marker} ${series.seriesName} : ${valueFormatter(value)}` : "";
                });

                return tooltipText;
            },
        },
        ...getLegend(data, config, baseFontSize),
        labelLayout: { hideOverlap: true },
        series: data.series_labels.map((label, index) => ({
            type: "line",
            name: getAxisLabel(label, data.metadata, config),
            data: data.result.map(entry => [entry.x.toDate(), entry.y[index]]),
            symbolSize: 0.15 * baseFontSize,
            lineStyle: { width: 0.07 * baseFontSize },
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.data[1], data.label_type.y, config),
            },
            ...getForecastingLine(data, config, baseFontSize),
        })),
    };
}
