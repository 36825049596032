import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ClickableDataGrid from "../../components/ClickableDataGrid";
import { formatDate, formatDateTime } from "../../util/formatter";
import CircularLoading from "../../components/loading/CircularLoading";
import AdvisorContainer from "../../layout/AdvisorContainer";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AppContext } from "../../AppRouter";
import WarningIcon from "@mui/icons-material/Warning";

const Scenario = () => {
    const { client, config, notify, user } = useContext(AppContext);
    const navigate = useNavigate();
    const [scenarios, setScenarios] = useState();

    const [toDelete, setToDelete] = useState();
    const [deleting, setDeleting] = useState(false);

    const columns = [
        {
            field: "name",
            headerName: config.i18n.scenario.list.name_column,
            flex: 4,
            sortable: false,
        },
        {
            field: "created_at",
            headerName: config.i18n.scenario.list.created_at_column,
            flex: 3,
            sortable: false,
            renderCell: params => (
                <span title={`${config.i18n.collector.created_at} ${formatDateTime(params.value, config.locale)} by ${params.row.created_by_name} `}>
                    {formatDate(params.value, config.locale)}
                </span>
            ),
        },
        {
            field: "actions",
            flex: 0,
            sortable: false,
            type: "actions",
            getActions: params => [
                <Tooltip key="edit" title={config.i18n.button.edit}>
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={config.i18n.button.edit}
                        disabled={!params.row.permission?.READ}
                        data-cy="scenario-edit"
                        color="info"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        component={RouterLink}
                        to={`/scenario/${params.id}/edit`}
                    />
                </Tooltip>,
                <Tooltip key="duplicate" title={config.i18n.button.clone}>
                    <GridActionsCellItem
                        icon={<ContentCopyIcon />}
                        label={config.i18n.button.clone}
                        disabled={!user.permissions?.FORECAST?.CREATE || !params.row.permission?.READ}
                        data-cy="scenario-clone"
                        color="info"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        component={RouterLink}
                        to={`/scenario/${params.id}/clone`}
                    />
                </Tooltip>,
                <Tooltip key="delete" title={config.i18n.button.delete}>
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={config.i18n.button.delete}
                        disabled={!params.row.permission?.DELETE}
                        data-cy="scenario-delete"
                        color="error"
                        onClick={(e) => {
                            e.stopPropagation();
                            setToDelete(params.row);
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    useEffect(() => {
        client.scenario.scenarioListScenarios()
            .then((scenarios) => {
                setScenarios(scenarios.response);
            })
            .catch((error) => {
                setScenarios([]);
                notify.error(error, "scenario.fetch");
            });
    }, []);

    if (!scenarios) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.scenario.list.loading} />
            </AdvisorContainer>
        );
    }

    return (
        <AdvisorContainer
            title={config.i18n.scenario.list.title}
            dataCyProp="scenarios_container"
            actions={(
                <Button
                    data-cy="scenarios-create_button"
                    variant="contained"
                    disabled={!user.permissions?.FORECAST?.CREATE}
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to="/scenario/create"
                    title={config.i18n.button.create}
                >
                    {config.i18n.button.create}
                </Button>
            )}
        >
            {
                toDelete
                    ? (
                        <Dialog
                            maxWidth="xs"
                            open={!!toDelete}
                        >
                            <DialogTitle>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <WarningIcon color="warning" sx={{ marginRight: 1 }} />
                                    {config.i18n.dialog.delete_scenario_title}
                                </Box>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    {config.i18n.dialog.delete_scenario + " "}
                                    <Box component="span" sx={{ fontWeight: "fontWeightMedium" }}>{toDelete.name}</Box>
                                    ?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={deleting} onClick={() => setToDelete(null)}>
                                    {config.i18n.button.cancel}
                                </Button>
                                <Button
                                    loading={deleting}
                                    loadingPosition="center"
                                    onClick={() => {
                                        setDeleting(true);
                                        client.scenario.scenarioDeleteScenario(toDelete.id)
                                            .then((scenario) => {
                                                setScenarios(scenarios.filter(el => el.id !== scenario.id));
                                                setDeleting(false);
                                                setToDelete(null);
                                            })
                                            .catch((error) => {
                                                notify.error(error, "scenario.delete");
                                                setDeleting(false);
                                                setToDelete(null);
                                            });
                                    }}
                                    data-cy="scenario_dialog-delete"
                                >
                                    {config.i18n.button.delete}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        )
                    : null
            }
            <ClickableDataGrid
                dataCy="scenarios_list"
                rows={scenarios}
                columns={columns}
                onRowClick={params => navigate(`/scenario/${params.id}/edit`)}
            />
        </AdvisorContainer>
    );
};

export default Scenario;
