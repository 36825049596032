import { getNumericalAxisOptions } from "./common";
import { getAxisLabel, getFormatter, getTooltipLabel } from "./formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    // get max diameter for diameter normalization
    const sizes = data.result.flatMap(data => Math.abs(data.z));
    const maxSize = Math.max(...sizes);

    const topLargestAbsIds = data.result
        .filter(r => r.z[0] !== Number.EPSILON) // Filter null values in z-axis
        .sort((r1, r2) => Math.abs(r2.z[0]) - Math.abs(r1.z[0])) // Order the ids by largest absolute value
        .slice(0, 5) // Get first 5 values
        .map(r => getAxisLabel(r.label, data.metadata, config)); // Only save the id

    return {
        labelLayout: { hideOverlap: true },
        xAxis: getNumericalAxisOptions(data.labels.x, data.result.map(el => el.x), config, data.label_type.x, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            confine: true,
            textStyle: { fontSize: 0.4 * baseFontSize },
            trigger: "item",
            formatter: (params) => {
                const xFormatter = getFormatter(data.label_type.x, config, false);
                const yFormatter = getFormatter(data.label_type.y, config, false);
                const zFormatter = getFormatter(data.label_type.z, config, false);

                const [x, y, z] = params.value;
                const tooltipName = getTooltipLabel(params.data.id, data.metadata, config);

                return `<b>${tooltipName}</b></br>`
                    + `${data.labels.x}: ${xFormatter(x)}</br>`
                    + `${data.labels.y}: ${yFormatter(y)}</br>`
                    + `${data.labels.z}: ${zFormatter(z)}`;
            },
        },
        series: [{
            type: "scatter",
            data: data.result.map(entry => ({
                id: entry.label,
                // lookup name, short name, i18n key or default to the value itself
                label: getAxisLabel(entry.label, data.metadata, config),
                value: [entry.x, entry.y[0], entry.z[0]],
            })),
            symbolSize: (value) => {
                const z = value[2];
                const minimumSize = 5;
                const size = Math.abs(z) / maxSize * baseFontSize * 1.5;
                return size < minimumSize ? minimumSize : size;
            },
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: value => topLargestAbsIds.includes(value.data.label) ? value.data.label : "",
            },
        }],
    };
};
