import dayjs from "dayjs";
import DimensionBox from "../DimensionBox";
import React, { useContext } from "react";
import SelectField from "../field/SelectField";
import ClickableDatePicker from "../ClickableDatePicker";
import { Autocomplete, Button, Checkbox, FormControlLabel, TextField, Grid2 as Grid } from "@mui/material";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { isForecastingEnabled } from "../../util/feature_flag_util";
import { AppContext } from "../../AppRouter";

const DateSelector = ({ disabled, date, dateAgg, annualize, cumulative, scenario, onUpdate }) => {
    const { config } = useContext(AppContext);

    // since dates are a URL parameter, inside a JSON object, it will not be deserialized as dayjs object.
    // so, we need to enforce dayjs here.
    const startDate = dayjs.utc(date[0]);
    const endDate = dayjs.utc(date[1]);

    const historicalMaxDate = config.time.historical.max;

    const referenceMinDate = config.time.reference.min;
    const referenceMaxDate = config.time.reference.max;

    const globalMinDate = config.time.global.min;
    const globalMaxDate = config.time.global.max;

    function getStartDateSelector() {
        return (
            <ClickableDatePicker
                dataCyProp="startDate_selector"
                views={["year", "month"]}
                format="MMM YYYY"
                label={config.i18n.Dimension.DATE_FROM}
                disabled={disabled}
                minDate={globalMinDate}
                maxDate={endDate}
                value={startDate}
                onAccept={val => onUpdate(val.startOf("month").startOf("date"), endDate, scenario?.selected, scenario?.cutoff_date)}
            />
        );
    }

    function getEndDateSelector() {
        return (
            <ClickableDatePicker
                dataCyProp="endDate_selector"
                views={["year", "month"]}
                format="MMM YYYY"
                label={config.i18n.Dimension.DATE_TO}
                disabled={disabled}
                minDate={startDate}
                maxDate={globalMaxDate}
                value={endDate}
                onAccept={val => onUpdate(startDate, val.endOf("month").endOf("date"), scenario?.selected, scenario?.cutoff_date)}
            />
        );
    }

    function getAllTimeDateSelector() {
        return (
            <Button
                data-cy="allTimeDate_button"
                variant="contained"
                color="grey"
                fullWidth
                disabled={disabled || (startDate.isSame(globalMinDate) && endDate.isSame(historicalMaxDate))}
                onClick={() => onUpdate(globalMinDate, historicalMaxDate, scenario?.selected, scenario?.cutoff_date)}
            >
                {config.i18n.selector.all_date_button}
            </Button>
        );
    }

    function getReferenceDateSelector() {
        return (
            <Button
                data-cy="referenceDate_button"
                variant="contained"
                color="grey"
                fullWidth
                disabled={disabled || (startDate.isSame(referenceMinDate) && endDate.isSame(referenceMaxDate))}
                onClick={() => onUpdate(referenceMinDate, referenceMaxDate, scenario?.selected, scenario?.cutoff_date)}
            >
                {config.i18n.selector.reference_date_button}
            </Button>
        );
    }

    function getAggDateSelector(param) {
        const value = param?.value || "";
        const options = param?.options || [];
        return (
            <SelectField
                dataCyProp="aggDate_selector"
                value={value}
                label={config.i18n.selector.date_agg}
                possibleValues={options}
                metadata={config.i18n.Dimension}
                disabled={disabled || !param}
                onChange={value => param.onUpdate(value)}
            />
        );
    }

    function getAnnualizeSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        data-cy="annualizeDate_checkbox"
                        checked={value}
                        disabled={disabled || param?.disabled || !param}
                        onChange={event => annualize.onUpdate(event.target.checked)}
                    />
                )}
                label={config.i18n.selector.annualize}
            />
        );
    }

    function getCumulativeSelector(param) {
        const value = param?.value || false;
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        data-cy="cumulative_checkbox"
                        checked={value}
                        disabled={disabled || !param}
                        onChange={event => param.onUpdate(event.target.checked)}
                    />
                )}
                label={config.i18n.selector.cumulative}
            />
        );
    }

    function getScenarioSelector(param) {
        const selectedScenario = param?.selected && param?.options.some(el => el.id === param.selected.id) ? scenario?.selected : null;
        return (
            <Autocomplete
                label={config.i18n.selector.scenario}
                forcePopupIcon
                value={selectedScenario}
                options={param?.options || []}
                getOptionLabel={option => option?.name || option}
                disabled={!isForecastingEnabled(config) || !param || disabled || isEmpty(param?.options) || dateAgg?.value === "FISCAL_YEAR"} // we do not support forecasting with FISCAL_YEAR aggregation
                size="small"
                onChange={(event, value) => {
                    let newEndDate = endDate;

                    // Check if the scenario was cleared
                    if (!selectedScenario) {
                        newEndDate = endDate < config.time.global.max ? endDate : config.time.global.max;
                    }

                    onUpdate(startDate, newEndDate, value, param.cutoff_date);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        autoComplete="off"
                        label={config.i18n.selector.scenario}
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                "value": selectedScenario ? params.inputProps.value : "",
                                "readOnly": true,
                                "data-cy": "scenario_autocomplete",
                            },
                        }}
                    />
                )}
            />
        );
    }

    function getCutoffDateSelector(param) {
        if (!isForecastingEnabled(config)) {
            return (
                <ClickableDatePicker
                    dataCyProp="cutoff_date_selector"
                    label={config.i18n.selector.cutoff_date}
                    views={["year", "month"]}
                    format="MMM YYYY"
                    disabled
                />
            );
        }

        const maxCutoffDate = config.time.cutoff_date;
        const cutoffDate = param?.cutoff_date ? dayjs.utc(param.cutoff_date) : maxCutoffDate;

        return (
            <ClickableDatePicker
                dataCyProp="cutoff_date_selector"
                label={config.i18n.selector.cutoff_date}
                views={["year", "month"]}
                format="MMM YYYY"
                disabled={!param || disabled || isEmpty(param?.options) || !param.selected || !cutoffDate}
                disableFuture
                minDate={globalMinDate}
                maxDate={maxCutoffDate}
                value={cutoffDate}
                onAccept={val => onUpdate(startDate, endDate, param.selected, val.startOf("month").startOf("date"))}
            />
        );
    }

    return (
        <DimensionBox title={config.i18n.customization_bar.date} collapsible>
            <Grid container spacing={1} rowSpacing={1.5} sx={{ alignItems: "center" }}>
                <Grid size={6}>
                    {getStartDateSelector()}
                </Grid>
                <Grid size={6}>
                    {getEndDateSelector()}
                </Grid>
                <Grid size={6}>
                    {getAllTimeDateSelector()}
                </Grid>
                <Grid size={6}>
                    {getReferenceDateSelector()}
                </Grid>
                <Grid size={6}>
                    {getAggDateSelector(dateAgg)}
                </Grid>
                <Grid size={6}>
                    {getAnnualizeSelector(annualize)}
                </Grid>
                <Grid size={6}>
                    {getScenarioSelector(scenario)}
                </Grid>
                <Grid size={6}>
                    {getCutoffDateSelector(scenario)}
                </Grid>
                <Grid size={6}>
                    {getCumulativeSelector(cumulative)}
                </Grid>
            </Grid>
        </DimensionBox>
    );
};

DateSelector.propTypes = {
    disabled: PropTypes.bool,
    date: PropTypes.array,
    defaultDate: PropTypes.array,
    defaultSetting: PropTypes.array,
    dateAgg: PropTypes.object,
    annualize: PropTypes.object,
    cumulative: PropTypes.object,
    scenario: PropTypes.object,
    onUpdate: PropTypes.func,
};

export default DateSelector;
